import { sort } from "@fyxer-ai/shared";
import { getTimezoneOffset } from "date-fns-tz";
import { toHeaderCase } from "js-convert-case";

import timeZones from "@/timeZones.json";

export const getTimezoneAbbreviation = (timezone: string) => {
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: timezone,
    timeZoneName: "short",
  });

  // Format the current date and extract the timezone abbreviation
  const parts = formatter.formatToParts(new Date());
  const timeZonePart = parts.find((part) => part.type === "timeZoneName");
  return timeZonePart?.value;
};

export const getTimeZones = () => {
  const rawTimeZones = timeZones as string[];

  return sort(
    sort(
      rawTimeZones.map((x) => x.toLowerCase()),
      (x) => x,
      "asc",
    ),
    (x) => getTimezoneOffset(x),
    "asc",
  ).map((x) => ({
    label:
      x
        .split("/")
        .map((y) => toHeaderCase(y))
        .join(" - ") + (getTimezoneAbbreviation(x) ? ` (${getTimezoneAbbreviation(x)})` : ""),
    value: x,
  }));
};
