import { useIsMobile } from "@/hooks/useIsMobile";

interface PageContainerProps {
  children: React.ReactNode;
  mobilePaddingHeight?: number;
}

export const PageContainer = ({ children }: PageContainerProps) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex ${!isMobile ? "h-full" : ""} w-full flex-col items-center justify-center bg-gray-100 ${!isMobile ? "px-4 pt-5" : ""}`}
    >
      {children}
    </div>
  );
};
