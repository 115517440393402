import {
  AutoJoinMeetingConfiguration,
  date,
  FREE_TRIAL_LENGTH_DAYS,
  getScopes,
  Membership,
  OauthConnection,
  OauthIntegration,
  OauthProvider,
  OauthServiceType,
  parseCustomEmailDomain,
  organisationConfigDefaults,
} from "@fyxer-ai/shared";
import { useQuery } from "@tanstack/react-query";
import { deleteDoc, QueryDocumentSnapshot, updateDoc } from "firebase/firestore";
import { LucideIcon, Pen, Tag, Video } from "lucide-react";
import { forwardRef, HTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";

import zoomLogo from "@/assets/company-logos/zoom.png";
import gmailUrl from "@/assets/company-logos/gmail.png";
import outlookUrl from "@/assets/company-logos/outlook.png";
import microsoftTeamsUrl from "@/assets/company-logos/microsoft-teams.png";
import zoomUrl from "@/assets/company-logos/zoom.png";
import googleMeetUrl from "@/assets/company-logos/google-meet.png";

import { ConfirmAlertDialog } from "@/components/ConfirmAlertDialog";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { CenterPage } from "@/components/layout/CenterPage";
import { Container } from "@/components/layout/Container";
import { Spinner, SpinnerPage } from "@/components/layout/SpinnerPage";
import { Badge } from "@/components/ui/badge";
import { Button, ButtonProps } from "@/components/ui/button";
import { useAuth } from "@/context/BaseContext/state/useAuth";
import { useUser } from "@/context/BaseContext/state/useUser";
import OnboardingPageWizard from "@/context/OrganisationContext/guards/_components/OnboardingPageWizard";
import { EventName, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useClickProps } from "@/hooks/useClickProps";
import { useUpdateState } from "@/hooks/useUpdateState";
import { useBreakpoint } from "@/hooks/useWindowSize";
import { unwrap } from "@/lib/firebase/unwrap";
import { LocalStorageBooleanKey, storage } from "@/lib/storage";
import { getMeetingConfigName } from "@/routes/org/[organisationId]/settings/tabs/configuration/ConfigurationTab";
import {
  GoogleDisclosure,
  integrationData,
  IntegrationDatum,
} from "@/routes/org/[organisationId]/settings/tabs/integrations/integrationData";
import { useOauthRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/useOauthRedirect";
import { useRecallZoomConnectionRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/ZoomIntegrationsSection";
import { InvitesForm } from "@/routes/org/[organisationId]/settings/tabs/team/InvitesDialog";

import { useOrganisation } from "../state/useOrganisation";
import { cn } from "@/lib/utils";
import { getCompanyName } from "@/lib/getWhitelabelProvider";

const ReconnectRow = ({ connection }: { connection: QueryDocumentSnapshot<OauthConnection> }) => {
  const { metadata, integration, organisationId } = connection.data();
  const email = metadata.email as string | undefined;
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;
  const { handleOauthRedirect } = useOauthRedirect();
  const refreshButtonProps = useClickProps({
    onClick: () => handleOauthRedirect({ integration, organisationId, type: "integration" }),
    buttonText: "Refresh",
  });
  return (
    <div className="flex items-center gap-x-4">
      <img alt={title} src={logoUrl} className="h-8 w-8 rounded-sm" />
      <h4>{title}</h4>
      {email ? <Badge variant="secondary">{email}</Badge> : null}
      <div className="flex-grow" />
      <Button {...refreshButtonProps} />
      <ConfirmAlertDialog
        title="Delete"
        warningText="If you delete the connection, you'll lose all associated data."
        fn={() => deleteDoc(connection.ref)}
      />
    </div>
  );
};

const OutOfDateConnectionsPage = ({
  outOfDateConnections,
}: {
  outOfDateConnections: QueryDocumentSnapshot<OauthConnection>[];
}) => {
  return (
    <Container className="space-y-4 py-12">
      <div className="space-y-2">
        <h1>Refresh your integrations</h1>
        <p className="text-slate-500">
          Occasionally we release updates that require us to refresh our access to your connections with{" "}
          {getCompanyName()}. Please refresh the following connections to return to the main dashboard.
        </p>
      </div>

      <div className="space-y-2">
        {outOfDateConnections.map((connection) => (
          <ReconnectRow key={connection.id} connection={connection} />
        ))}
      </div>
    </Container>
  );
};

type NoConnectionsPageState = {
  autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration;
  areLabelsEnabled: boolean;
  redirectingIntegration: OauthIntegration | undefined;
};

const ConnectionButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    integration: OauthIntegration;
    redirectingIntegration: OauthIntegration | undefined;
    connectIntegration: (integration: OauthIntegration) => Promise<void>;
  }
>(({ integration, redirectingIntegration, connectIntegration, ...props }, ref) => {
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;

  return (
    <Button
      variant="outline"
      size="lg"
      className="w-full flex-grow drop-shadow-xl"
      {...props}
      ref={ref}
      onClick={() => connectIntegration(integration)}
      disabled={!!redirectingIntegration}
    >
      {redirectingIntegration === integration ? (
        <Spinner className="m-0 flex-grow-0" />
      ) : (
        <img src={logoUrl} className="h-4 w-4" />
      )}
      Connect{redirectingIntegration === integration ? "ing" : ""} {title}
    </Button>
  );
});

const CircleIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { Icon: LucideIcon }>(
  ({ Icon, className, ...props }, ref) => (
    <div
      className={cn("flex h-12 w-12 items-center justify-center rounded-full bg-purple-100", className)}
      {...props}
      ref={ref}
    >
      <Icon className="stroke-purple-500" size={24} />
    </div>
  ),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NoEmailConnectionPage = ({ userMembership }: { userMembership: QueryDocumentSnapshot<Membership> }) => {
  const { logEvent } = useAnalytics();
  const [state, updateState] = useUpdateState<NoConnectionsPageState>({
    autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration.ALWAYS,
    areLabelsEnabled: true,
    redirectingIntegration: undefined,
  });
  const { handleOauthRedirect } = useOauthRedirect();
  const breakpoint = useBreakpoint();
  const buttonsRef = useRef<HTMLDivElement>(null);

  useLogPageView(EventPage.NO_EMAIL_CONNECTION);

  const connectIntegration = async (integration: OauthIntegration) => {
    const { organisationId } = userMembership.data();
    updateState({ redirectingIntegration: integration });
    await updateDoc(userMembership.ref, {
      autoJoinMeetingConfiguration: state.autoJoinMeetingConfiguration,
      areLabelsEnabled: state.areLabelsEnabled,
    });
    const isGoogle = [OauthIntegration.GMAIL, OauthIntegration.GOOGLE_CALENDAR].includes(integration);
    const rest = isGoogle
      ? { type: "provider" as const, provider: OauthProvider.GOOGLE }
      : { type: "integration" as const, integration };
    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
    logEvent(EventName.COMPLETE_OAUTH_CONNECTION, { integration, organisationId });
  };

  return (
    <Container className="max-w-2xl space-y-8 py-12 max-sm:space-y-6 max-sm:py-4">
      <h1 className="text-center text-3xl max-sm:text-xl">
        Connect your email to start your free {FREE_TRIAL_LENGTH_DAYS} day trial
      </h1>

      <div className="space-y-4">
        <p>{getCompanyName()} works by connecting to your email and calendar. Once we're connected, we'll:</p>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Tag} className="flex-shrink-0" />
          <p>Screen low priority emails from your inbox and highlight what's important</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Pen} className="flex-shrink-0" />
          <p>Leave draft replies in email threads you need to reply to or follow up on</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Video} className="flex-shrink-0" />
          <p>Join meetings and recording, transcribe and take notes</p>
        </div>
        <p>We never send email on your behalf. We leave drafts for you to edit and send.</p>
        <p>If it doesn't work out with us, we'll leave your inbox as we found it.</p>
      </div>

      <div
        className="space-y-8 max-sm:space-y-4"
        style={{
          marginBottom: breakpoint === "xs" && buttonsRef.current ? buttonsRef.current.offsetHeight : undefined,
        }}
      >
        <div className="space-y-2">
          <p className="text-sm font-medium">Categorise my inbox</p>
          <SelectUtil
            value={state.areLabelsEnabled ? "yes" : "no"}
            onChange={(value) => updateState({ areLabelsEnabled: value === "yes" })}
            items={["yes", "no"]}
          />
        </div>

        <div className="space-y-2">
          <p className="text-sm font-medium">Meetings the notetaker should join</p>
          <SelectUtil
            value={state.autoJoinMeetingConfiguration}
            onChange={(value) => updateState({ autoJoinMeetingConfiguration: value })}
            items={Object.values(AutoJoinMeetingConfiguration).map((value) => ({
              value,
              label: getMeetingConfigName(value),
            }))}
          />
        </div>
      </div>

      <div
        className="space-y-4 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:border-t max-sm:border-slate-100 max-sm:bg-white max-sm:p-4"
        ref={buttonsRef}
      >
        <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
          <ConnectionButton
            {...{
              integration: OauthIntegration.GMAIL,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
          <ConnectionButton
            {...{
              integration: OauthIntegration.MICROSOFT_OUTLOOK_EMAIL,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
        </div>

        <GoogleDisclosure />
      </div>
    </Container>
  );
};

const NoCalendarConnectionPage = ({ organisationId }: { organisationId: string }) => {
  useLogPageView(EventPage.NO_CALENDAR_CONNECTION);

  const { logEvent } = useAnalytics();

  const [state, updateState] = useUpdateState<{ redirectingIntegration: OauthIntegration | undefined }>({
    redirectingIntegration: undefined,
  });
  const { handleOauthRedirect } = useOauthRedirect();
  const buttonsRef = useRef<HTMLDivElement>(null);

  const connectIntegration = async (integration: OauthIntegration) => {
    updateState({ redirectingIntegration: integration });
    const isGoogle = [OauthIntegration.GMAIL, OauthIntegration.GOOGLE_CALENDAR].includes(integration);
    const rest = isGoogle
      ? { type: "provider" as const, provider: OauthProvider.GOOGLE }
      : { type: "integration" as const, integration };
    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
    logEvent(EventName.COMPLETE_OAUTH_CONNECTION, { integration, organisationId });
  };

  return (
    <Container className="max-w-2xl space-y-12 py-12">
      <h1 className="text-3xl">Connect your calendar</h1>

      <div className="space-y-4">
        <p>{getCompanyName()} works by connecting to your email and calendar. Once we're connected, we'll:</p>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Tag} className="flex-shrink-0" />
          <p>Screen low priority emails from your inbox and highlight what's important</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Pen} className="flex-shrink-0" />
          <p>Leave draft replies in email threads you need to reply to or follow up on</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Video} className="flex-shrink-0" />
          <p>Join meetings and recording, transcribe and take notes</p>
        </div>
        <p>We never send email on your behalf. We leave drafts for you to edit and send.</p>
        <p>If it doesn't work out with us, we'll leave your inbox as we found it.</p>
      </div>

      <div
        className="space-y-4 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:border-t max-sm:border-slate-100 max-sm:bg-white max-sm:p-4"
        ref={buttonsRef}
      >
        <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
          <ConnectionButton
            {...{
              integration: OauthIntegration.GOOGLE_CALENDAR,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
          <ConnectionButton
            {...{
              integration: OauthIntegration.MICROSOFT_OUTLOOK_CALENDAR,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
        </div>

        <GoogleDisclosure />
      </div>
    </Container>
  );
};

const NoZoomConnectionPage = ({ organisationId, markSeen }: { organisationId: string; markSeen: () => void }) => {
  useLogPageView(EventPage.NO_ZOOM_CONNECTION);

  const { redirectToAuthUrl } = useRecallZoomConnectionRedirect();

  const handleSkip = () => {
    storage.local.boolean(LocalStorageBooleanKey.WAS_ZOOM_CONNECTION_SKIPPED).set(true);
    markSeen();
  };

  const redirectToZoomClickProps = useClickProps({
    onClick: () => redirectToAuthUrl(organisationId),
    buttonText: "Connect Zoom",
  });

  return (
    <CenterPage className="space-y-8 max-sm:space-y-4">
      <img src={zoomLogo} className="m-auto h-8 w-8 rounded" />
      <h1 className="text-center text-3xl max-sm:text-xl">Do you use Zoom?</h1>
      <p className="text-center">
        Connect your zoom account with {getCompanyName()} so our Notetaker can take the minutes from your video meetings
      </p>
      <div className="space-y-2">
        <Button {...redirectToZoomClickProps} className="w-full" />
        <Button variant="ghost" onClick={handleSkip} className="w-full">
          Skip for now
        </Button>
      </div>
    </CenterPage>
  );
};

export const TeamInvitePage = ({ markSeen }: { markSeen: (() => void) | undefined }) => {
  useLogPageView(EventPage.ADD_TEAM);
  const { organisationId } = useOrganisation();

  const api = useApi();
  const [state, updateState] = useUpdateState<{ invitees: string[] | undefined }>({ invitees: undefined });
  const recommendedInvitees = useQuery({
    queryKey: ["recommendedInvitees", organisationId],
    queryFn: async () => {
      const emails = await api.organisations.id(organisationId).invites.recommend();
      updateState({ invitees: emails });
      return emails;
    },
    refetchOnWindowFocus: false,
  });

  const handleNext = () => {
    storage.local.boolean(LocalStorageBooleanKey.WAS_TEAMS_SIGNUP_SKIPPED).set(true);
    markSeen?.();
  };

  if (recommendedInvitees.isLoading) return <SpinnerPage />;

  return (
    <CenterPage className="max-w-[540px] space-y-4">
      <h1 className="text-center text-3xl max-sm:text-xl">Give your teammates a free trial</h1>
      {state.invitees !== undefined && (
        <InvitesForm initialInviteEmails={state.invitees} showRole={false} afterSubmit={() => handleNext()} />
      )}
      {markSeen && (
        <Button variant="ghost" onClick={handleNext} className="w-full">
          Skip for now
        </Button>
      )}
    </CenterPage>
  );
};

const OnboardingIntegrationImage = ({ src, alt }: { src: string; alt: string }) => (
  <img src={src} alt={alt} className="h-10 w-10 rounded-lg border border-slate-100" />
);

export const OnboardingIntegrationsRack = ({
  integrations,
  text = "Pairs with:",
}: {
  integrations: { src: string; alt: string }[];
  text?: string;
}) => (
  <div className="flex items-center gap-x-4">
    <p>{text}</p>
    <div className="flex gap-x-2">
      {integrations.map(({ src, alt }) => (
        <OnboardingIntegrationImage src={src} alt={alt} key={src} />
      ))}
    </div>
  </div>
);

export const OnboardingPage = ({ markSeen }: { markSeen: (() => void) | undefined }) => {
  useLogPageView(EventPage.ONBOARDING);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow-0 overflow-scroll">
        <Container className="max-w-2xl space-y-12 py-24 max-sm:space-y-8 max-sm:pt-12">
          <h1 className="text-center">How it works</h1>
          <iframe
            src="https://player.vimeo.com/video/1032518275"
            className="aspect-video w-full rounded-xl drop-shadow-xl"
            allowFullScreen
            frameBorder="0"
          />
          <p>
            When you check your inbox, every email requiring a response will have a reply drafted in your style, ready
            for you to send.
          </p>

          <OnboardingIntegrationsRack
            integrations={[
              { src: gmailUrl, alt: "Gmail" },
              { src: outlookUrl, alt: "Outlook" },
            ]}
          />

          <img
            className="w-full"
            src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/6745d468c22121ad5142ad89_fyxerai-bg-here.jpg"
          />

          <p>
            All emails will be organized into folders, making it easy to spot what needs your attention. Now that you’ve
            connected your email, we’ve categorized your latest 100 emails.
          </p>

          <img
            className="w-full"
            src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/6745d6bf62b4310e165dc055_fyxer-here-2.png"
          />

          <p>
            Spam, marketing emails, and AI-generated junk will be filtered out to keep your inbox clean and manageable.
          </p>

          <img
            className="w-full"
            src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/6745d6dbbe8185abbb610140_fyxer-here.png"
          />

          <p>…making it easy to respond quickly, while keeping it personal.</p>

          <h2>Writes your meeting notes and follow-up emails</h2>

          <p>
            {getCompanyName()} will ask to join your meetings to take notes. As soon as the meeting ends, you'll find
            better-than-human meeting notes in your inbox, plus a follow-up email ready to send.
          </p>

          <OnboardingIntegrationsRack
            integrations={[
              { src: zoomUrl, alt: "Zoom" },
              { src: googleMeetUrl, alt: "Google Meet" },
              { src: microsoftTeamsUrl, alt: "Microsoft Teams" },
            ]}
          />

          <img
            className="w-full"
            src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/6746182c39e686349203a6bd_video-call_fyxer-min.jpg"
          />

          <h2>Continuously learning and improving to become your personal assistant</h2>

          <p>
            {getCompanyName()} is securely trained on your past emails, proactively learning what defines you—your
            voice, your expertise, your priorities, and your unique, personal touch.
          </p>

          {/* <img className="w-full" /> */}

          <p>
            Every meeting it joins, and every email you send, helps train {getCompanyName()} to write emails just like
            you. Over time, it becomes so precise, you can simply hit send.
          </p>

          <div className="space-y-4 rounded-3xl bg-slate-900 p-6 sm:p-8">
            <h2 className="text-white">Private and Secure</h2>
            <p className="text-white">
              Fyxer AI has been verified by a designated third party security by ISO 27001 v2022 and Soc 2 Type 1. Your
              emails are never used to train general AI models. Only you have access to your private assistant.
            </p>
          </div>
        </Container>
      </div>
      {markSeen && (
        <Container className="max-w-2xl flex-shrink-0 flex-grow-0 border-t border-slate-100 p-4">
          <Button
            className="w-full"
            onClick={() => {
              storage.local.boolean(LocalStorageBooleanKey.WAS_ONBOARDING_SKIPPED).set(true);
              markSeen();
            }}
          >
            Next step
          </Button>
        </Container>
      )}
    </div>
  );
};

export const ConnectionsGuard = ({ children }: { children: ReactNode }) => {
  const { userMembership, planData, connections, organisationId, recallZoomConnections, isAdmin, organisation } =
    useOrganisation();
  const { user } = useUser();
  const { userId } = useAuth();
  const { getFeatureFlag, logEvent } = useAnalytics();
  const { planType } = planData;
  const canOnlyAdminsInviteUsers =
    organisation.canOnlyAdminsInviteUsers ?? organisationConfigDefaults.canOnlyAdminsInviteUsers;
  const canInviteUsers = isAdmin || !canOnlyAdminsInviteUsers;

  const connectionsThatCantBeOutOfDate = connections.filter(
    unwrap((connection) => !connection.isOrgLevel || connection.userId === userId),
  );
  const outOfDateConnections = connectionsThatCantBeOutOfDate.filter(
    unwrap((connection) => {
      const { approvedScopes, integration } = connection;
      const requestedScopes = getScopes({ integration, mode: "validate" });
      const hasAllScopes = requestedScopes.every((scope) => approvedScopes.includes(scope));
      return !hasAllScopes;
    }),
  );

  const onboardingPageInstatedAt = date.init({ year: 2024, month: 11, day: 23 });

  const [state, updateState] = useUpdateState({
    hasSeenOnboardingPage:
      storage.local.boolean(LocalStorageBooleanKey.WAS_ONBOARDING_SKIPPED).get() ||
      user.createdAt < onboardingPageInstatedAt,
    hasSeenZoomConnectionPage: storage.local.boolean(LocalStorageBooleanKey.WAS_ZOOM_CONNECTION_SKIPPED).get(),
    hasSeenTeamsSignupPage: storage.local.boolean(LocalStorageBooleanKey.WAS_TEAMS_SIGNUP_SKIPPED).get(),
  });

  const [onboardingHowItWorksWiz, setOnboardingHowItWorksWiz] = useState(false);

  const emailConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.EMAIL),
  );

  const calendarConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.CALENDAR),
  );

  useEffect(() => {
    // Measure users who haven't seen onboarding and have no connections and stop over inflating counts
    if (planType !== "FREE" && (emailConnections.length === 0 || calendarConnections.length === 0)) {
      logEvent(EventName.EXPERIMENT_TRIGGER, {
        flag_name: "onboarding-how-it-works-wizard",
        flag_value: getFeatureFlag("onboarding-how-it-works-wizard"),
      });
      if (getFeatureFlag("onboarding-how-it-works-wizard") === "test") {
        storage.local.boolean(LocalStorageBooleanKey.WAS_ONBOARDING_SKIPPED).set(true);
        setOnboardingHowItWorksWiz(true);
      }
    }
  }, [getFeatureFlag, planType, logEvent, calendarConnections.length, emailConnections.length]);

  if (outOfDateConnections.length > 0) return <OutOfDateConnectionsPage outOfDateConnections={outOfDateConnections} />;

  if (onboardingHowItWorksWiz && planType !== "FREE") {
    if (emailConnections.length === 0) {
      return <OnboardingPageWizard userMembership={userMembership} tab="email" />;
    } else if (calendarConnections.length === 0) {
      return <OnboardingPageWizard userMembership={userMembership} tab="calendar" />;
    }
  }

  if (onboardingHowItWorksWiz === false) {
    if (emailConnections.length === 0 && planType !== "FREE")
      return <NoEmailConnectionPage userMembership={userMembership} />;

    if (calendarConnections.length === 0 && planType !== "FREE")
      return <NoCalendarConnectionPage organisationId={organisationId} />;

    if (!state.hasSeenOnboardingPage) {
      return <OnboardingPage markSeen={() => updateState({ hasSeenOnboardingPage: true })} />;
    }
  }

  if (recallZoomConnections.length === 0 && !state.hasSeenZoomConnectionPage && planType !== "FREE")
    return (
      <NoZoomConnectionPage
        organisationId={organisationId}
        markSeen={() => updateState({ hasSeenZoomConnectionPage: true })}
      />
    );

  const hasCustomDomain = !!parseCustomEmailDomain(user.email);

  if (hasCustomDomain && !state.hasSeenTeamsSignupPage && canInviteUsers)
    return <TeamInvitePage markSeen={() => updateState({ hasSeenTeamsSignupPage: true })} />;

  return children;
};
