import { constructUrl, OauthIntegration } from "@fyxer-ai/shared";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { EventName, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useQueryParams } from "@/hooks/useQueryParams";
import { LocalStorageStringKey, storage } from "@/lib/storage";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

const useIntegrationThrows = () => {
  const { integration } = useParams();

  try {
    return z.nativeEnum(OauthIntegration).parse(integration);
  } catch {
    // don't break the app but have a graceful fallback
    return integration;
  }
};

function isValidIntegration(value: string | undefined): value is OauthIntegration {
  if (value) {
    return Object.values(OauthIntegration).includes(value as OauthIntegration);
  }
  return false;
}

export const OauthRedirectPage = () => {
  const api = useApi();
  const integration = useIntegrationThrows();
  const { submitSurvey, logEvent } = useAnalytics();
  const [showThanks, setShowThanks] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [orgId, setOrgId] = useState<string | null>(null);
  const { code, state, error, error_description } = useQueryParams();
  const formSchema = z.object({
    feedback: z.string().min(2),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      feedback: "",
    },
  });

  const integrationExists = isValidIntegration(integration);

  const hasError =
    !integrationExists ||
    requestError ||
    !code ||
    (!state ? true : storage.local.string(LocalStorageStringKey.OAUTH_STATE_ID).get() !== state) ||
    error ||
    error_description;

  useLogPageView(EventPage.OAUTH, { integration });

  useEffect(() => {
    (async () => {
      if (integrationExists && !hasError && orgId === null && state && code) {
        try {
          const organisationInviteId = await api.oauth.exchangeAuthCode({
            integration,
            code,
            state,
            type: "integration",
          });
          setOrgId(organisationInviteId);
        } catch (err) {
          if (err instanceof AxiosError) {
            setRequestError(err.response?.data?.message ?? err.message);
          } else {
            setRequestError((err as { message: string }).message);
          }
        }
      }
    })();
  }, [hasError, integrationExists, integration, api.oauth, code, state, orgId]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const SURVEY_ID = "01949848-45b0-0000-84be-4d1423d96509";
    submitSurvey(SURVEY_ID, values.feedback);
    setShowThanks(true);
  }

  useEffect(() => {
    if (orgId && !hasError) {
      logEvent(EventName.COMPLETE_OAUTH_CONNECTION, { integration, organisationId: orgId });
    }
  }, [orgId, integration, hasError, logEvent]);

  if (orgId && !hasError) {
    return (
      <Navigate
        to={constructUrl({
          path: `/org/${orgId}/settings`,
          hash: { tab: "integrations" },
        })}
      />
    );
  }

  return hasError ? (
    <div className="mx-auto flex max-w-lg items-center justify-center px-3" style={{ height: "calc(100vh - 48px)" }}>
      <div>
        <Card>
          <CardHeader>
            <CardTitle className="mb-3 leading-8">
              An error occurred when connecting with{" "}
              <span style={{ fontFamily: "inherit" }} className="capitalize">
                {integration?.split?.("-").join(" ")}
              </span>
            </CardTitle>
            <CardDescription>
              To ensure we can deliver the best experience for you with Fyxer AI, please provide any relevant details on
              why the connection didn't work:
            </CardDescription>
          </CardHeader>
          <CardContent>
            {showThanks ? (
              <p className="text-center">Thank you for your feedback!</p>
            ) : (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <FormField
                    control={form.control}
                    name="feedback"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="mb-3 block">Feedback</FormLabel>
                        <FormControl>
                          <Textarea placeholder="Enter your feedback here..." {...field} />
                        </FormControl>
                        <FormMessage className="mt-3" />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" className="mt-3">
                    Send feedback
                  </Button>
                </form>
              </Form>
            )}
          </CardContent>
        </Card>

        <div className="mt-6 text-center">
          <Link to={constructUrl({ path: "/org" })}>
            <Button variant="link">Back to Fyxer AI</Button>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerPage />
  );
};
