import { SchedulingStates } from "@/routes/scheduling/[schedulingRequestId]/SchedulingRequestView";
import { Calendar, Globe, Clock, Hourglass, VideoIcon } from "lucide-react";
import { getTimeZoneIndicator } from "@fyxer-ai/shared";
import { toHeaderCase } from "js-convert-case";

const formatDateRange = (startsAt: Date, endsAt: Date) => {
  const getOrdinal = (day: number) => {
    if (day > 3 && day < 21) return "th"; // Special case for 11th-19th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const startsDay = startsAt.getDate();
  const endsDay = endsAt.getDate();

  const startsOrdinal = getOrdinal(startsDay);
  const endsOrdinal = getOrdinal(endsDay);

  const startsMonth = startsAt.toLocaleDateString("en-US", { month: "long" });
  const endsMonth = endsAt.toLocaleDateString("en-US", { month: "long" });
  const year = startsAt.getFullYear();

  if (startsMonth === endsMonth) {
    return `${startsDay}${startsOrdinal} - ${endsDay}${endsOrdinal} ${startsMonth} ${year}`;
  }
  return `${startsDay}${startsOrdinal} ${startsMonth} - ${endsDay}${endsOrdinal} ${endsMonth} ${year}`;
};

const EventDetailItem = ({ icon: Icon, text }: { icon: React.ElementType; text: string }) => (
  <div className="flex items-center gap-x-3">
    <Icon className="h-5 w-5 text-gray-600" />
    <p className="text-sm text-gray-700">{text}</p>
  </div>
);

type EventDetailsPanelProps = {
  schedulingRange: {
    startsAt: Date;
    endsAt: Date;
  };
  schedulersTimeZone: string;
  durationMins: number;
  title: string;
  userName: string;
  chosenTime?: { startsAt: Date; endsAt: Date };
  meetingLink?: string;
};

export const EventDetailsPanel = ({
  eventDetails,
  state,
}: {
  eventDetails: EventDetailsPanelProps;
  state: SchedulingStates;
}) => {
  const readableTimeZone =
    eventDetails.schedulersTimeZone
      .split("/")
      .map((y) => toHeaderCase(y))
      .join(" - ") + ` (${getTimeZoneIndicator({ timeZone: eventDetails.schedulersTimeZone })})`;

  const eventDetailsItems = [
    <EventDetailItem
      icon={Calendar}
      text={
        state === "time-selection"
          ? formatDateRange(eventDetails.schedulingRange.startsAt, eventDetails.schedulingRange.endsAt)
          : `${eventDetails.chosenTime?.startsAt.toLocaleDateString("en-US", { weekday: "long", month: "long", day: "numeric" }) ?? ""}`
      }
    />,
    <EventDetailItem icon={Hourglass} text={`${eventDetails.durationMins} minutes`} />,
    <EventDetailItem icon={Globe} text={readableTimeZone} />,
  ];
  if (state !== "time-selection") {
    eventDetailsItems.splice(
      1,
      0,
      <EventDetailItem
        icon={Clock}
        text={
          eventDetails.chosenTime?.startsAt.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }) ?? ""
        }
      />,
    );
  }
  if (state === "confirmation") {
    eventDetailsItems.push(<EventDetailItem icon={VideoIcon} text={eventDetails.meetingLink ?? "Loading..."} />);
  }

  const footerText = state !== "confirmation" ? "A Google Meets link will be provided on setup" : "";

  return (
    <div>
      <h2 className="text-lg font-semibold leading-6">{eventDetails.title}</h2>
      <div className="mt-4 space-y-2 sm:space-y-3">{eventDetailsItems}</div>
      <footer className="mt-6 text-sm leading-5 text-gray-500">{footerText}</footer>
    </div>
  );
};
