import { EmailConnectionSetupStatus, OauthConnection, OauthIntegration } from "@fyxer-ai/shared";
import { format } from "date-fns";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { HelpCircle, Loader2 } from "lucide-react";
import { ReactNode } from "react";

import { Badge } from "@/components/ui/badge";
import { Progress } from "@/components/ui/progress";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { getCompanyName } from "@/lib/getWhitelabelProvider";

const getProgressMessage = (progress: number) => {
  if (progress < 0.1) return "Creating connection...";
  if (progress < 0.25) return "Setting up connection...";
  if (progress < 0.35) return "Syncing emails...";
  if (progress < 1) return "Categorising emails...";
  return "Synced";
};

const ErrorBadge = ({ title, children }: { title: string; children: ReactNode }) => (
  <Tooltip delayDuration={0}>
    <TooltipTrigger asChild>
      <div>
        <Badge variant="destructive" className="flex gap-x-2">
          {title}
          <HelpCircle color="#FFFFFF" size={16} />
        </Badge>
      </div>
    </TooltipTrigger>
    <TooltipContent className="max-w-[400px] space-y-2">{children}</TooltipContent>
  </Tooltip>
);
export const ConnectionSyncIndicator = ({
  connection,
  emailConnectionSetupStatuses,
}: {
  connection: QueryDocumentSnapshot<OauthConnection>;
  emailConnectionSetupStatuses: QueryDocumentSnapshot<EmailConnectionSetupStatus>[];
}) => {
  const { integration, isSyncInProgress, createdAt } = connection.data();
  const isEmailIntegration = [OauthIntegration.GMAIL, OauthIntegration.MICROSOFT_OUTLOOK_EMAIL].includes(integration);
  const emailConnectionSetupStatus = emailConnectionSetupStatuses.find((status) => status.id === connection.id);

  if (!isEmailIntegration || !isSyncInProgress || !emailConnectionSetupStatus)
    return <p className="hidden text-sm sm:block">Connected {format(createdAt, "do MMM yyyy")}</p>;

  const { progress, errorCode } = emailConnectionSetupStatus.data();

  const providerName = integration === OauthIntegration.GMAIL ? "Gmail" : "Outlook";

  switch (errorCode) {
    case "ABOVE_RATE_LIMIT":
      return (
        <ErrorBadge title="Rate limit hit">
          <p>
            We were unable to connect with your inbox at this time because {providerName} told us there's currently too
            much activity going on.
          </p>
          <p>
            {providerName} imposes limits on how much external tools like {getCompanyName()} can interact with your
            inbox ("rate limits"). Unfortunately, we don't have any control of the limits they set.
          </p>
          <p>
            The {providerName} rate limits limits apply across all tools/plugins you use, so its possible another tool
            you use is interacting too much with your inbox, preventing us connecting.
          </p>
          <p>
            Sometimes this issue is resolved by waiting a while, disconnecting and reconnecting. If not, you can try
            disabling tools that have access to your {providerName} that you don't need.
          </p>
        </ErrorBadge>
      );
    case "MAILBOX_INACCESSIBLE":
      return (
        <ErrorBadge title="Mailbox inaccessible">
          <p>
            When we tried to connect to your mailbox, {providerName} told us that it's not configured in a way that
            allows us to access it and do what we need to do.
          </p>
          <p>This can be for a few reasons:</p>
          <p>
            - Your company's IT team has a setting enabled that disallows external tools like {getCompanyName()} for
            interacting with your email.
          </p>
          <p>- You're lacking a Google Workspace or Microsoft 365 license for your email address</p>
          <p>
            - You have an old version of {providerName} that doesn't support access by external tools like{" "}
            {getCompanyName()}
          </p>
          <p>- Your email is hosted on premise, meaning we can't access it</p>
          <p>
            You'll need to change some settings on your side to allow us to connect to you, then disconnect and
            reconnect on the dashboard here.
          </p>
        </ErrorBadge>
      );
    case "UNKNOWN":
      return (
        <ErrorBadge title="Connection error">
          <p>When we tried to connect to your mailbox, {providerName} raised an error we weren't expecting.</p>
          <p>
            This error was been raised with our engineering team automatically. We're looking into it now and will have
            answers in the next couple of working days.
          </p>
          <p>
            Occassionally this is caused by a one-off error with {providerName} that disappears after a while, so it's
            worth disconnecting and trying to reconnect again in an hour to see if the issue is resolved.
          </p>
        </ErrorBadge>
      );
    default:
  }

  const progressMessage = getProgressMessage(progress);

  return (
    <div className="flex items-center gap-x-2">
      <Loader2 className="m-auto h-6 w-6 animate-spin" />
      <p>{progressMessage}</p>
      <Progress className="h-6 w-48 rounded-xl" value={progress * 100} />
    </div>
  );
};
