import { format } from "date-fns";
import { toHeaderCase } from "js-convert-case";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableCell, TableRow } from "@/components/ui/table";
import { useBillingPeriodEndDate } from "@/context/OrganisationContext/state/useBillingPeriodEndDate";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useClickProps } from "@/hooks/useClickProps";
import { redirectExternally } from "@/lib/redirectExternally";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { SubscriptionPlanType } from "@fyxer-ai/shared";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useUpdateState } from "@/hooks/useUpdateState";
import { ReactNode } from "react";

const PlanChangeCard = ({ isUpgrade }: { isUpgrade: boolean }) => {
  const { organisationId, subscription } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const { toast } = useToast();
  const [state, updateState] = useUpdateState({ isDialogOpen: false });
  const api = useApi();

  if (!subscription) throw new Error("Must have a subscription to view this page");

  const { seatCount, billingCycle } = subscription.data();
  const clickProps = useClickProps({
    onClick: async () => {
      logButtonPress(isUpgrade ? EventButton.UPGRADE_TO_PRO : EventButton.DOWNGRADE_TO_STANDARD, {
        organisationId,
        subscriptionId: subscription.id,
      });
      await api.organisations
        .id(organisationId)
        .subscriptions.id(subscription.id)
        .update({
          billingCycle,
          planType: isUpgrade ? SubscriptionPlanType.PRO : SubscriptionPlanType.STANDARD,
          seatCount,
        });

      updateState({ isDialogOpen: false });

      toast({
        title: `${isUpgrade ? "Upgrade" : "Downgrade"} successful`,
        description: `Your team is now on the ${isUpgrade ? "Pro" : "Standard"} Plan!`,
      });
    },
    buttonText: isUpgrade ? "Upgrade" : "Downgrade",
  });

  const ConfirmDialog = ({ trigger }: { trigger: ReactNode }) => (
    <Dialog open={state.isDialogOpen} onOpenChange={(open) => updateState({ isDialogOpen: open })}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isUpgrade ? "Upgrade to the Pro plan" : "Downgrade to the Standard plan"}</DialogTitle>
          <DialogDescription>
            {isUpgrade
              ? "By upgrading, you'll be charged $50/user/seat rather than $30/user/seat. You'll stil get a discount if paying annually."
              : "By downgrading, your team will lose access to your custom branded Meeting Notetaker, the Hubspot integration and multiple email and calendar connections per user."}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="secondary" onClick={() => updateState({ isDialogOpen: false })}>
            Cancel
          </Button>
          <Button {...clickProps} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  if (!isUpgrade)
    return (
      <p className="text-sm text-slate-500">
        To downgrade from the Pro plan to Standard, click{" "}
        <ConfirmDialog trigger={<span className="cursor-pointer font-bold hover:text-black">here</span>} />
      </p>
    );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Upgrade to the Pro Plan</CardTitle>
        <CardDescription>
          Teams on the Pro Plan get a custom branded Meeting Notetaker, multiple email and calendar accounts per user,
          and access to the Hubspot and Salesforce integrations.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <ConfirmDialog trigger={<Button>Upgrade to Pro</Button>} />
      </CardFooter>
    </Card>
  );
};

export const ProPlanDowngrade = () => {
  const { organisationId, subscription } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const { toast } = useToast();
  const api = useApi();

  if (!subscription) throw new Error("Must have a subscription to view this page");

  const { seatCount, billingCycle } = subscription.data();

  const downgradeClickProps = useClickProps({
    onClick: async () => {
      logButtonPress(EventButton.UPGRADE_TO_PRO, { organisationId, subscriptionId: subscription.id });
      await api.organisations.id(organisationId).subscriptions.id(subscription.id).update({
        billingCycle,
        planType: SubscriptionPlanType.STANDARD,

        seatCount,
      });

      toast({ title: "Downgrade successful", description: "Your team is now on the Standard Plan!" });
    },
    buttonText: "here",
  });

  return (
    <p className="flex items-center">
      To downgrade from the Pro plan to Standard, click{" "}
      <Button variant="ghost" className="text-base" {...downgradeClickProps}>
        here
      </Button>
      .
    </p>
  );
};

export const SubscriptionView = () => {
  const { organisationId, subscription } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const api = useApi();

  if (!subscription) throw new Error("Must have a subscription to view this page");

  const { seatCount, billingCycle, planType, willCancelAtEndOfCurrentBillingPeriod } = subscription.data();
  const willCancel = willCancelAtEndOfCurrentBillingPeriod ?? false;

  const { data: billingPeriodEndDate, isLoading: isBillingPeriodEndDateLoading } = useBillingPeriodEndDate(
    subscription.id,
  );

  const handleGoToStripe = async () => {
    logButtonPress(EventButton.GO_TO_STRIPE, {
      subscriptionId: subscription.id,
    });
    const portalUrl = await api.organisations.id(organisationId).portalSessions.create();
    redirectExternally(portalUrl);
  };

  const goToStripeClickProps = useClickProps({
    onClick: handleGoToStripe,
    buttonText: "Manage subscription",
  });

  return (
    <div className="space-y-4">
      <h2>Your subscription</h2>
      <Table className="max-w-96">
        <TableRow>
          <TableCell>Plan</TableCell>
          <TableCell>
            <Badge>{toHeaderCase(planType)}</Badge>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Seats</TableCell>
          <TableCell>{seatCount}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Billing cycle</TableCell>
          <TableCell>{toHeaderCase(billingCycle)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{willCancel ? "Cancels on" : "Next billing date"}</TableCell>
          <TableCell>
            {isBillingPeriodEndDateLoading || !billingPeriodEndDate ? (
              <Skeleton className="h-4 w-16 rounded" />
            ) : (
              format(billingPeriodEndDate, "dd-MM-yyyy")
            )}
          </TableCell>
        </TableRow>
      </Table>
      {planType === SubscriptionPlanType.STANDARD && <PlanChangeCard isUpgrade={true} />}
      {planType === SubscriptionPlanType.PRO && <PlanChangeCard isUpgrade={false} />}

      <Button {...goToStripeClickProps} variant="secondary" />
    </div>
  );
};
