import { OauthServiceType } from "@fyxer-ai/shared";
import { useQuery } from "@tanstack/react-query";
import { getCountFromServer, query, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import { Container } from "@/components/layout/Container";
import { Logo } from "@/components/layout/TopBar";
import { Button } from "@/components/ui/button";
import { useBase } from "@/context/BaseContext/state/useBase";
import { Collection } from "@/lib/firebase/Collection";
import { cn } from "@/lib/utils";
import { CallRecordingView } from "@/routes/org/[organisationId]/meeting-assistant/call-recordings/[callRecordingId]/CallRecordingView";
import { getCompanyName, getMarketingSiteUrl } from "@/lib/getWhitelabelProvider";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";

export const OnboardingBanner = () => {
  const { getFeatureFlag, logButtonPress } = useAnalytics();

  const trackSignUpClick = () => {
    logButtonPress(EventButton.MEETING_NOTE_CTA_CLICK);
  };

  return getFeatureFlag("referral-meeting-summary-banner") === "test" ? (
    <div className="fixed left-0 top-0 z-50 h-[46px] w-full border-b border-slate-500 bg-white">
      <div className="m-auto flex h-full max-w-4xl items-center gap-x-2 px-4 py-2 sm:gap-x-4">
        <Link to={getMarketingSiteUrl()} target="_blank" rel="noreferrer">
          <Logo className="md:max-w-20" />
        </Link>
        <p className="text-xs text-slate-500 md:text-sm">
          Record, transcribe, and share meeting notes with {getCompanyName()}
        </p>
        <div className="flex-grow" />
        <Link onClick={trackSignUpClick} to="/org" target="_blank" rel="noreferrer" className="flex-shrink-0">
          <Button className="h-8">Get started</Button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="fixed left-0 top-0 w-full border-b border-slate-500 bg-white">
      <div className="m-auto flex h-full max-w-4xl items-center gap-x-4 px-4 py-2">
        <Link to={getMarketingSiteUrl()} target="_blank" rel="noreferrer" className="flex-shrink-0">
          <Logo />
        </Link>

        <p className="text-sm text-slate-500">
          {getCompanyName()} organizes your inbox, drafts extraordinary emails and writes better-than-human meeting
          notes.
        </p>
        <div className="flex-grow" />
        <Link onClick={trackSignUpClick} to="/org" target="_blank" rel="noreferrer" className="flex-shrink-0">
          <Button>Start free trial</Button>
        </Link>
      </div>
    </div>
  );
};

export const OnboardingBannerIfNeeded = () => {
  const { authUser } = useBase();
  const userId = authUser.value?.uid;
  const isUnauthenticated = !authUser.isLoading && !authUser.value;

  const emailConnectionCount = useQuery({
    queryKey: ["emailConnectionCount"],
    queryFn: async () => {
      if (!userId) return undefined;
      const emailConnectionCountAggregation = await getCountFromServer(
        query(
          Collection.OauthConnection,
          where("serviceType", "==", OauthServiceType.EMAIL),
          where("userId", "==", userId),
        ),
      );

      return emailConnectionCountAggregation.data().count;
    },
    refetchOnWindowFocus: false,
  });

  const shouldOnboard = isUnauthenticated || emailConnectionCount.data === 0;

  return shouldOnboard ? <OnboardingBanner /> : null;
};

export const SharedRecordingPage = () => {
  const { memberships, authUser } = useBase();
  const needsToOnboard =
    (!authUser.isLoading && !authUser.value) ||
    (!memberships.isLoading && (!memberships.value || memberships.value.length === 0));

  return (
    <Container className={cn("flex h-full flex-col space-y-8", needsToOnboard ? "pt-12" : "pt-12")}>
      <OnboardingBannerIfNeeded />
      <CallRecordingView />
    </Container>
  );
};
