import { configDefaults, OauthServiceType, OrganisationRole } from "@fyxer-ai/shared";

import { unwrap } from "@/lib/firebase/unwrap";
import { getPlanType } from "@/routes/org/[organisationId]/settings/tabs/billing/getPlanType";

import { useUser } from "../../BaseContext/state/useUser";
import { useOrganisationNullable } from "./useOrganisationNullable";

export const useOrganisation = () => {
  const { userId, freeTrialEndsAt } = useUser();
  const {
    organisationId,
    organisation: { value: organisation, isLoading: isOrganisationLoading },
    memberships: { value: memberships, isLoading: areMembershipsLoading },
    invites: { value: invites, isLoading: areInvitesLoading },
    connections: { value: connections, isLoading: areConnectionsLoading },
    orgLevelConnections: { value: orgLevelConnections, isLoading: areOrgLevelConnectionsLoading },
    emailConnectionSetupStatuses: {
      value: emailConnectionSetupStatuses,
      isLoading: areEmailConnectionSetupStatusesLoading,
    },
    subscription: { value: subscription, isLoading: isSubscriptionLoading },
    recallZoomConnections: { value: recallZoomConnections, isLoading: areRecallZoomConnectionsLoading },
    teams: { value: teams, isLoading: areTeamsLoading },
  } = useOrganisationNullable();

  if (!organisationId) throw new Error("organisation id is missing");

  if (isOrganisationLoading) throw new Error("organisation is still loading");
  if (areMembershipsLoading) throw new Error("memberships are still loading");
  if (areInvitesLoading) throw new Error("invites are still loading");
  if (areConnectionsLoading) throw new Error("connections are still loading");
  if (areOrgLevelConnectionsLoading) throw new Error("org level connections are still loading");
  if (areEmailConnectionSetupStatusesLoading) throw new Error("email connection setup statuses are still loading");
  if (isSubscriptionLoading) throw new Error("subscription is still loading");
  if (areRecallZoomConnectionsLoading) throw new Error("recall zoom connections are still loading");
  if (areTeamsLoading) throw new Error("teams are still loading");

  if (organisation === undefined) throw new Error("organisation is missing");
  if (memberships === undefined) throw new Error("memberships are missing");
  if (invites === undefined) throw new Error("invites are missing");
  if (connections === undefined) throw new Error("connections are missing");
  if (orgLevelConnections === undefined) throw new Error("org level connections are missing");
  if (emailConnectionSetupStatuses === undefined) throw new Error("email connection setup statuses are missing");
  if (recallZoomConnections === undefined) throw new Error("recall zoom connections are missing");
  if (teams === undefined) throw new Error("subscription is missing");

  const userMembership = memberships.find((membership) => membership.data().userId === userId);

  if (!userMembership) throw new Error("no user membership to organisation");

  const isAdmin = userMembership.data().role === OrganisationRole.ADMIN;

  const planData = getPlanType({ organisation, freeTrialEndsAt, subscription });

  const calendarConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.CALENDAR),
  );

  const timeZoneFromCalendarConnection = calendarConnections.reduce<string | undefined>((acc, connection) => {
    if (acc) return acc;
    const timeZone = connection.data().metadata.timeZone as string | undefined;
    return timeZone;
  }, undefined);

  const timeZoneFromMembership = userMembership.data().timeZone;
  const timeZone = timeZoneFromMembership ?? timeZoneFromCalendarConnection ?? configDefaults.timeZone;

  return {
    organisationId,
    organisation,
    memberships,
    invites,
    connections: [...connections, ...orgLevelConnections],
    emailConnectionSetupStatuses,
    recallZoomConnections,
    subscription,
    userMembership,
    isAdmin,
    planData,
    timeZone,
    calendarConnections,
    teams,
  };
};
