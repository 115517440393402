import { constructReferralLink } from "@fyxer-ai/shared";

import { AccordionUtil } from "@/components/AccordionUtil";
import { ReferralTermsView } from "@/components/layout/TopBar";
import { useUser } from "@/context/BaseContext/state/useUser";

import { EmailCategoryExplainer } from "../configuration/ConfigurationTab";
import { getCompanyName } from "@/lib/getWhitelabelProvider";

export const ConnectionFaqsSection = () => {
  const { user } = useUser();
  const { slug } = user;
  const referralLink = slug ? constructReferralLink(slug) : "";
  return (
    <div>
      <h2>FAQs</h2>
      <AccordionUtil
        items={[
          {
            title: "What if I already have an email label system?",
            content: (
              <p>
                You can add your rules in the "Preferences" tab in the {getCompanyName()} dashboard. And have those
                emails going into the folders you want. Alternatively, if you're worried about your existing inbox
                system being disrupted, you can turn off the "Categorise emails" feature in the "Preferences" tab.
              </p>
            ),
          },
          {
            title: "What do each of the email categories represent?",
            content: <EmailCategoryExplainer />,
          },
          {
            title: "Will my system go back to normal if I don't like the product?",
            content: <p>If you delete your account. Your inbox will revert to its previous system instantly.</p>,
          },
          {
            title: "How can I get my email signature included on drafts?",
            content: (
              <p>
                If you’d like your drafts to include your signature please go into the Preference tab and scroll down
                until the email signature section. Once there, copy and paste your signature from the settings page on
                Gmail/Outlook into our signature box and press "Update".
              </p>
            ),
          },
          {
            title: "When will my drafts appear?",
            content: (
              <p>
                Once you connect your email to {getCompanyName()}, any new emails that require a response will have one
                drafted.
              </p>
            ),
          },
          {
            title: "For Microsoft users - Why are my folders empty?",
            content: (
              <p>
                When you sign up, we only file emails categorised as "Marketing" or "Notification" into folders. If you
                want to file different categories, go to the Preferences tab and scroll to the "Emails to show in main
                inbox" section.
              </p>
            ),
          },
          {
            title: "How does the Calendar feature work?",
            content: (
              <p>
                If you’d like {getCompanyName()} to help you with scheduling, please connect your Calendar above. You
                can see how our scheduling feature works{" "}
                <a href="https://intercom.help/fyxerai-helpcenter/en/articles/9155097-how-does-scheduling-work">here</a>
                .
              </p>
            ),
          },
          {
            title: "Is there a referral scheme?",
            content: <ReferralTermsView referralLink={referralLink} />,
          },
        ]}
      />
    </div>
  );
};
