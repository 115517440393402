import { useQuery } from "@tanstack/react-query";
import { getDownloadURL, ref } from "firebase/storage";

import { storage } from "@/lib/firebase/app";

export const StorageVideoPlayer = ({ storagePath, className }: { storagePath: string; className?: string }) => {
  const downloadUrlQuery = useQuery({
    queryKey: ["callRecording", storagePath],
    queryFn: async () => {
      const downloadUrl = await getDownloadURL(ref(storage, storagePath));
      return downloadUrl;
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div className={className}>
      {downloadUrlQuery.isLoading ? (
        <div>Loading</div>
      ) : downloadUrlQuery.error ? (
        <div>Error</div>
      ) : downloadUrlQuery.data ? (
        <video controls width="100%" className="rounded-2xl">
          <source src={downloadUrlQuery.data} type="video/mp4" />
        </video>
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};
