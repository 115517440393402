import {
  AutoJoinMeetingConfiguration,
  configDefaults,
  constructUrl,
  deduplicate,
  EmailFont,
  getFriendlyLabelName,
  getLanguageName,
  LabelName,
  LanguageCode,
  Membership,
  OauthServiceType,
  sort,
  SubscriptionPlanType,
  UpsellLocation,
  getIsOnPlanEqualToOrHigherThan,
} from "@fyxer-ai/shared";
import { toHeaderCase, toLowerCase } from "js-convert-case";
import { AlertCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { ArrayField } from "@/components/controls/ArrayField";
import { ComboboxUtil } from "@/components/controls/ComboboxUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { HTMLPaster } from "@/components/controls/HTMLPaster";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { Input, NumberInput } from "@/components/ui/input";
import { SliderRoot, SliderThumb, SliderTrack } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventForm, useAnalytics } from "@/hooks/useAnalytics";
import { unwrap } from "@/lib/firebase/unwrap";
import { getTimeZones } from "@/lib/getTimeZones";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";
import { cn } from "@/lib/utils";

import { labelData } from "./labelData";
import { getCompanyName } from "@/lib/getWhitelabelProvider";
import { useRemoteConfig } from "@/context/BaseContext/state/useRemoteConfig";

const ConnectionWarning = ({ serviceTypes }: { serviceTypes: OauthServiceType[] }) => {
  const { connections, organisationId } = useOrganisation();

  const unconnectedServceTypes = serviceTypes.filter((serviceType) =>
    connections.every(unwrap((connection) => connection.serviceType !== serviceType)),
  );
  const hasConnections = unconnectedServceTypes.length === 0;

  if (hasConnections) return null;

  const integrationsPath = constructUrl({
    path: `/org/${organisationId}/settings`,
    hash: { tab: "integrations" },
  });

  return (
    <div className="flex items-center gap-x-2 rounded-xl border border-black bg-slate-50 p-4">
      <AlertCircle />
      <p className="text-sm text-gray-500">
        You need to{" "}
        <Link to={integrationsPath} className="font-semibold text-black hover:text-gray-500">
          connect your {unconnectedServceTypes.map(toLowerCase).join(" and ")}
        </Link>{" "}
        for this feature to work.
      </p>
    </div>
  );
};

const PlanWarning = ({ minimumPlanType }: { minimumPlanType: SubscriptionPlanType | "FREE" }) => {
  const {
    organisationId,
    planData: { planType },
  } = useOrganisation();

  if (getIsOnPlanEqualToOrHigherThan(minimumPlanType)(planType)) return null;

  const billingPath = constructUrl({
    path: `/org/${organisationId}/settings`,
    hash: { tab: "billing" },
  });

  return (
    <div className="flex items-center gap-x-2 rounded-xl border border-black bg-slate-50 p-4">
      <AlertCircle />
      <p className="text-sm text-gray-500">
        You need to{" "}
        <Link to={billingPath} className="font-semibold text-black hover:text-gray-500">
          {minimumPlanType === SubscriptionPlanType.STANDARD
            ? "upgrade to a paid plan"
            : `upgrade to the ${minimumPlanType} plan`}
        </Link>{" "}
        to enable this feature. You're currently on the {toHeaderCase(planType)} plan.
      </p>
    </div>
  );
};

export const getMeetingConfigName = (config: AutoJoinMeetingConfiguration) => {
  switch (config) {
    case AutoJoinMeetingConfiguration.ALWAYS:
      return "All meetings";
    case AutoJoinMeetingConfiguration.ONLY_MINE:
      return "Meetings I'm hosting";
    case AutoJoinMeetingConfiguration.ONLY_EXTERNAL:
      return "External meetings";
    case AutoJoinMeetingConfiguration.NEVER:
      return "None";
  }
};

export const EmailCategoryExplainer = () => (
  <div className="space-y-3">
    {labelData.map((datum) => (
      <div className={cn("flex items-center gap-x-4")} key={datum.labelName}>
        <div
          className={cn(
            "flex w-32 flex-shrink-0 flex-grow-0 items-center justify-center gap-x-1 rounded-lg px-2 py-1 text-center text-xs font-semibold",
            datum.color,
          )}
        >
          {getFriendlyLabelName(datum.labelName)}
        </div>
        <p className="flex-grow text-sm">{datum.criteria}</p>
      </div>
    ))}
  </div>
);

export const ConfigurationTab = () => {
  const { userMembership, timeZone, organisationId } = useOrganisation();
  const { logFormSubmit } = useAnalytics();
  const { updateMembership } = useOrganisationActions();
  const { organisationIdsWhereCalendarLinkIsEnabled } = useRemoteConfig();

  const {
    languageCode,
    font,
    fontColor,
    fontSize,
    areLabelsEnabled,
    areDraftsEnabled,
    emailRuleItems,
    labelNamesInInbox,
    alternativeEmails,
    areFollowUpsEnabled,
    isConversationViewEnabled,
    emailSignature,
    followUpHours,
    schedulingLink,
    appEmails,
    autoJoinMeetingConfiguration,
    upsellLocations,
    shouldSendAttendeesCallRecording,
    shouldSendReferralNotificationEmails,
    shouldSendMeetingRecordingFailureEmails,
    shouldUseFyxerSchedulingLink,
  } = userMembership.data();

  return (
    <FormUtil
      schema={z.object({
        areLabelsEnabled: z.boolean(),
        labelNamesInInbox: z.array(z.nativeEnum(LabelName)),
        isConversationViewEnabled: z.boolean(),
        alternativeEmails: z.array(makeSchemaSafe(z.string().email().max(320))),
        emailRuleItems: z.array(
          z.object({
            emailAddress: makeSchemaSafe(z.string().min(1).max(320)),
            labelName: z.enum(["NONE", ...Object.values(LabelName)]),
          }),
        ),
        autoJoinMeetingConfiguration: z.nativeEnum(AutoJoinMeetingConfiguration),
        shouldSendReferralNotificationEmails: z.boolean(),
        shouldSendMeetingRecordingFailureEmails: z.boolean(),

        areDraftsEnabled: z.boolean(),
        appEmails: z.object({
          schedulingProposal: z.boolean(),
          schedulingAcceptance: z.boolean(),
        }),
        languageCode: z.nativeEnum(LanguageCode),
        font: z.nativeEnum(EmailFont),
        fontColor: makeSchemaSafe(z.string().max(320)).optional(),
        fontSize: z.union([z.literal(0), z.number().int().gte(8).lte(16).optional()]),
        emailSignature: z.string(),
        schedulingLink: makeSchemaSafe(z.string().max(2000)).optional(),

        areFollowUpsEnabled: z.boolean(),
        followUpHours: z
          .number()
          .int()
          .gte(1)
          .lte(24 * 14),

        timeZone: makeSchemaSafe(z.string().max(400)),
        shouldUpsellInMeetingFollowUpDrafts: z.boolean(),
        shouldUpsellInCalendarDrafts: z.boolean(),
        shouldSendAttendeesCallRecording: z.boolean(),
        shouldUseFyxerSchedulingLink: z.boolean(),
      })}
      defaultValues={{
        areLabelsEnabled: areLabelsEnabled ?? configDefaults.areLabelsEnabled,
        emailRuleItems: emailRuleItems ?? configDefaults.emailRuleItems,
        labelNamesInInbox: labelNamesInInbox ?? configDefaults.labelNamesInInbox,
        alternativeEmails: alternativeEmails ?? configDefaults.alternativeEmails,
        isConversationViewEnabled: isConversationViewEnabled ?? configDefaults.isConversationViewEnabled,
        shouldSendReferralNotificationEmails:
          shouldSendReferralNotificationEmails ?? configDefaults.shouldSendReferralNotificationEmails,
        shouldSendMeetingRecordingFailureEmails:
          shouldSendMeetingRecordingFailureEmails ?? configDefaults.shouldSendMeetingRecordingFailureEmails,

        autoJoinMeetingConfiguration: autoJoinMeetingConfiguration ?? configDefaults.autoJoinMeetingConfiguration,

        areDraftsEnabled: areDraftsEnabled ?? configDefaults.areDraftsEnabled,
        appEmails: appEmails ?? configDefaults.appEmails,
        languageCode: languageCode ?? configDefaults.languageCode,
        font: font ?? configDefaults.font,
        fontColor: fontColor ?? configDefaults.fontColor,
        fontSize: fontSize ?? configDefaults.fontSize,
        emailSignature: emailSignature ?? "",
        schedulingLink: schedulingLink ?? "",

        areFollowUpsEnabled: areFollowUpsEnabled ?? configDefaults.areFollowUpsEnabled,
        followUpHours: followUpHours ?? configDefaults.followUpHours,

        timeZone,

        shouldUpsellInMeetingFollowUpDrafts: (upsellLocations ?? configDefaults.upsellLocations).includes(
          UpsellLocation.MEETING_FOLLOW_UP_DRAFTS,
        ),
        shouldUpsellInCalendarDrafts: (upsellLocations ?? configDefaults.upsellLocations).includes(
          UpsellLocation.CALENDAR_DRAFTS,
        ),
        shouldSendAttendeesCallRecording:
          shouldSendAttendeesCallRecording ?? configDefaults.shouldSendAttendeesCallRecording,
        shouldUseFyxerSchedulingLink: shouldUseFyxerSchedulingLink ?? configDefaults.shouldUseFyxerSchedulingLink,
      }}
      submitTitle="Update"
      successMessage="Configuration updated"
      render={(form) => (
        <div className="space-y-16 pb-12">
          <div className="space-y-8">
            <div className="space-y-4">
              <h2>Email categorisation</h2>
              <p className="text-sm">We'll sort your email into the following categories:</p>
              <EmailCategoryExplainer />
            </div>

            <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.STANDARD} />

            <FormFieldUtil
              isInline
              control={form.control}
              name="areLabelsEnabled"
              title="Enable email categorisation"
              render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
            />
            {form.getValues("areLabelsEnabled") && (
              <>
                <div className="space-y-4">
                  <h4>Emails to show in main inbox</h4>
                  <p className="text-sm text-slate-500">
                    Without {getCompanyName()}, all emails you receive land in your main inbox.
                  </p>
                  <p className="text-sm text-slate-500">
                    If you switch a category off here, emails in that category will be filed away in their folder
                    (Outlook) or label (Gmail), and won't be shown in your main inbox.
                  </p>
                  <p className="text-sm text-slate-500">
                    We recommend filing away marketing emails and meeting updates to keep your inbox more manageable.
                  </p>

                  {Object.values(LabelName).map((labelName) => (
                    <div className="flex items-center gap-x-2" key={labelName}>
                      <FormFieldUtil
                        isInline
                        control={form.control}
                        name="labelNamesInInbox"
                        title={getFriendlyLabelName(labelName)}
                        render={({ field }) => (
                          <Switch
                            checked={field.value.includes(labelName)}
                            onCheckedChange={(checked) => {
                              const currentValues = form.getValues("labelNamesInInbox");
                              const newValues = checked
                                ? sort(deduplicate([...currentValues, labelName]), (x) => x, "asc")
                                : sort(
                                    currentValues.filter((l) => l !== labelName),
                                    (x) => x,
                                    "asc",
                                  );
                              field.onChange(newValues);
                            }}
                          />
                        )}
                      />
                      {[LabelName.AWAITING_REPLY, LabelName.ACTIONED].includes(labelName) && (
                        <p className="text-sm text-slate-500">Will only appear in the Sent folder in Outlook</p>
                      )}
                    </div>
                  ))}
                </div>
                <div className="space-y-2">
                  <h4 className="text-lg font-semibold">Email rules</h4>
                  <p className="text-sm text-slate-500">
                    Specify email addresses or domains to automatically assign to a label. For example, if there's a
                    newsletter you're interested in, you can assign it to FYI so it doesn't go to marketing.
                  </p>
                  <ArrayField
                    form={form}
                    name="emailRuleItems"
                    maxLength={400}
                    generateEmptyDatum={() => ({
                      emailAddress: "",
                      labelName: LabelName.TO_RESPOND,
                    })}
                    render={(index) => (
                      <div className="flex flex-grow items-center gap-x-2">
                        <FormFieldUtil
                          className="flex-grow"
                          control={form.control}
                          name={`emailRuleItems.${index}.emailAddress`}
                          render={({ field }) => <Input {...field} />}
                          isLabelHidden
                        />
                        <FormFieldUtil
                          control={form.control}
                          name={`emailRuleItems.${index}.labelName`}
                          render={({ field }) => (
                            <SelectUtil
                              className="w-40"
                              items={([...Object.values(LabelName), "NONE"] as (LabelName | "NONE")[])
                                .filter(
                                  (labelName) =>
                                    !(
                                      [LabelName.ACTIONED, LabelName.AWAITING_REPLY] as (LabelName | "NONE")[]
                                    ).includes(labelName),
                                )
                                .map((labelName) => ({
                                  label: labelName === "NONE" ? "No label" : getFriendlyLabelName(labelName),
                                  value: labelName,
                                }))}
                              {...field}
                            />
                          )}
                          isLabelHidden
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <h4 className="text-lg font-semibold">Alternative emails</h4>
                  <p className="text-sm text-slate-500">
                    Specify other email addresses you send from apart from your main email address, so they're labelled
                    as Actioned or Awaiting Reply.
                  </p>
                  <ArrayField
                    form={form}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    name={"alternativeEmails" as any}
                    maxLength={20}
                    generateEmptyDatum={() => ""}
                    render={(index) => (
                      <FormFieldUtil
                        className="flex-grow"
                        control={form.control}
                        name={`alternativeEmails.${index}`}
                        render={({ field }) => <Input {...field} />}
                        isLabelHidden
                      />
                    )}
                  />
                </div>
              </>
            )}
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h2>Drafts</h2>
              <p className="text-sm">
                When you get an email you need to reply to, we'll leave a draft response in your inbox for you to send
                or edit.
              </p>

              <div className="space-y-2 rounded-xl border border-black bg-slate-50 p-8">
                <p className="text-sm">
                  For Drafts to work properly,{" "}
                  <b>your Gmail/Outlook needs to group emails in the same thread together</b>. This is because we leave
                  the drafts we generate in the relevant thread.
                </p>
                <p className="text-sm">
                  By default, both Gmail and Outlook group emails by thread. If this isn't happening:
                </p>
                <p className="text-sm">
                  <b>Gmail</b>: Click the settings cog in the top right, scroll to the bottom, enable Conversation View.
                </p>
                <p className="text-sm">
                  <b>Outlook</b>: Click the settings cog in the top right, scroll to "Message Organization" and select
                  "Show email grouped by conversation".
                </p>
              </div>
            </div>

            <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.GROWTH} />

            <FormFieldUtil
              isInline
              control={form.control}
              name="areDraftsEnabled"
              title="Enable drafts"
              render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
            />
            {form.getValues("areDraftsEnabled") && (
              <div className="space-y-4">
                <FormFieldUtil
                  control={form.control}
                  name="emailSignature"
                  description="To ensure your signature displays correctly, you should copy it directly from your Gmail/Outlook settings, instead of from an email you've sent."
                  render={({ field }) => <HTMLPaster {...field} />}
                />
                <FormFieldUtil
                  control={form.control}
                  name="font"
                  render={({ field }) => (
                    <SelectUtil
                      {...field}
                      items={Object.values(EmailFont).map((value) =>
                        value === EmailFont.INHERIT ? { value, label: "Gmail/Outlook default" } : value,
                      )}
                    />
                  )}
                />
                <FormFieldUtil
                  control={form.control}
                  name="fontSize"
                  description="Set this value to 0 to inherit the font size from your email client."
                  render={({ field }) => <NumberInput value={field.value ?? 0} onChange={field.onChange} />}
                />
                <FormFieldUtil control={form.control} name="fontColor" render={({ field }) => <Input {...field} />} />
                <FormFieldUtil
                  control={form.control}
                  name="languageCode"
                  title="Language"
                  render={({ field }) => (
                    <SelectUtil
                      items={Object.values(LanguageCode).map((code) => ({
                        label: getLanguageName(code),
                        value: code,
                      }))}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h2>Follow ups</h2>

              <p className="text-sm">
                When you enable Follow Ups, we'll keep track of emails you've sent that you're expecting a reply to, and
                draft a follow up message for you to send after the amount of days you specify.
              </p>
            </div>

            <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.GROWTH} />
            <FormFieldUtil
              isInline
              control={form.control}
              name="areFollowUpsEnabled"
              title="Enable follow ups"
              render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
            />
            {form.getValues("areFollowUpsEnabled") && (
              <div className="space-y-4">
                <FormFieldUtil
                  control={form.control}
                  name="followUpHours"
                  title="Days before following up"
                  render={({ field }) => (
                    <SliderRoot
                      defaultValue={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                      step={24}
                      min={24}
                      max={24 * 14}
                      className="mt-8"
                    >
                      <SliderTrack />
                      <SliderThumb label={(field.value / 24).toFixed(0)} />
                    </SliderRoot>
                  )}
                />
              </div>
            )}
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <div className="flex items-center gap-x-4">
                <h2>Calendar drafts</h2>
              </div>
              <p className="text-sm">
                When someone sends you an email asking to meet, we'll use your calendar to pick the best times for a
                meeting, send a response informed by your calendar, and draft a calendar invite for you to send.
              </p>
            </div>
            <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL, OauthServiceType.CALENDAR]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.GROWTH} />

            <div className="space-y-4">
              {organisationIdsWhereCalendarLinkIsEnabled?.includes?.(organisationId) && (
                <FormFieldUtil
                  isInline
                  control={form.control}
                  name="shouldUseFyxerSchedulingLink"
                  title="Use Fyxer's calendar link to enhance scheduling"
                  description="When someone asks what times you're available for a meeting, we'll create a link they can click to see some of your available slots."
                  render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
                />
              )}
              <FormFieldUtil
                control={form.control}
                name="schedulingLink"
                description="A link others can use to schedule meetings with you, such as Calendly."
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldUtil
                control={form.control}
                name="timeZone"
                render={({ field }) => <ComboboxUtil {...field} items={getTimeZones()} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="appEmails.schedulingProposal"
                title="Email when proposing times"
                description="When someone asks what times you're available for a meeting, we'll email you with a view of the best slots you have available, in the context of your other events."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="appEmails.schedulingAcceptance"
                title="Email when accepting times"
                description="When you lock in a meeting time with someone, we'll send an email with a link to create the calendar event."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />

              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldUpsellInCalendarDrafts"
                title={`Mention ${getCompanyName()} in calendar drafts`}
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
            </div>
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h2>Meeting notetaker</h2>
              <p className="text-sm">
                You can find a list of upcoming meetings eligible for the Meeting Notetaker (they have a meeting link
                and two or more attendees) in the Meeting Notetaker section of the dashboard. You can control which
                meetings to send the assistant to there. When the meeting ends, we'll email a meeting summary, and leave
                an email draft in your inbox for you to send to the meeting's attendees.
              </p>
            </div>

            <ConnectionWarning serviceTypes={[OauthServiceType.CALENDAR, OauthServiceType.EMAIL]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.GROWTH} />

            <>
              <FormFieldUtil
                control={form.control}
                name="autoJoinMeetingConfiguration"
                title="Should join meetings automatically"
                description="If this setting is on, we'll automatically set the switch in the Upcoming Meetings tab of the Meeting Notetaker section to 'on', meaning our Meeting Notetaker will join them. You can set the switch to off to let the bot know not to join."
                render={({ field }) => (
                  <SelectUtil
                    {...field}
                    items={Object.values(AutoJoinMeetingConfiguration).map((value) => ({
                      value,
                      label: getMeetingConfigName(value),
                    }))}
                  />
                )}
              />

              {/* <FormFieldUtil
                isInline
                control={form.control}
                name="shouldUpsellInMeetingFollowUpDrafts"
                title=`Mention ${getCompanyName()} in meeting follow ups`
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              /> */}
              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldSendMeetingRecordingFailureEmails"
                title="Send meeting notetaker failure emails"
                description="When you tell us to send the meeting notetaker to a call and it fails to record, we'll send you an email explaining why."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldSendAttendeesCallRecording"
                title="Send call recording to attendees"
                description="When the meeting ends, we'll email the attendees a secure link to the call recording."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
            </>
          </div>
        </div>
      )}
      renderSubmitButton={(submitProps) => {
        return (
          <Button className="fixed bottom-4 w-[calc(100vw-32px)] max-w-[540px]" {...submitProps}>
            Update
          </Button>
        );
      }}
      onSubmit={async ({ shouldUpsellInCalendarDrafts, shouldUpsellInMeetingFollowUpDrafts, ...formData }) => {
        const upsellLocations = [
          ...(shouldUpsellInCalendarDrafts ? [UpsellLocation.CALENDAR_DRAFTS] : []),
          ...(shouldUpsellInMeetingFollowUpDrafts ? [UpsellLocation.MEETING_FOLLOW_UP_DRAFTS] : []),
        ];
        const data: Partial<Membership> = {
          ...formData,
          upsellLocations,
        };
        logFormSubmit(EventForm.UPDATE_CONFIGURATION, {
          areDraftsEnabled: data.areDraftsEnabled,
        });
        await updateMembership(data);
      }}
    />
  );
};
