import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { useUpdateState } from "@/hooks/useUpdateState";
import { Collection } from "@/lib/firebase/Collection";
import { emptyLoadedValue, LoadedValue } from "@/types/LoadedValue";
import { CalendarSchedulingRequest } from "@fyxer-ai/shared";
import { doc, onSnapshot } from "firebase/firestore";
import { createContext, ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";

type SchedulingRequestState = {
  schedulingRequest: LoadedValue<CalendarSchedulingRequest>;
  schedulingRequestId: LoadedValue<string>;
};

const initialSchedulingRequestState: SchedulingRequestState = {
  schedulingRequest: emptyLoadedValue,
  schedulingRequestId: emptyLoadedValue,
};

export const SchedulingRequestContext = createContext<SchedulingRequestState>(initialSchedulingRequestState);

export const SchedulingRequestProvider = ({ children }: { children: ReactNode }) => {
  const { schedulingRequestId } = useParams();
  if (!schedulingRequestId) {
    throw new Error("No schedulingRequestId provided");
  }

  const [state, updateState] = useUpdateState<SchedulingRequestState>({
    ...initialSchedulingRequestState,
    schedulingRequestId: { value: schedulingRequestId, isLoading: false },
  });

  useEffect(() => {
    updateState({
      schedulingRequestId: { value: schedulingRequestId, isLoading: false },
      schedulingRequest: emptyLoadedValue,
    });

    const callSchedulingRequest = onSnapshot(doc(Collection.CalendarSchedulingRequest, schedulingRequestId), (doc) =>
      updateState({ schedulingRequest: { value: doc.data(), isLoading: false } }),
    );

    return () => {
      callSchedulingRequest();
    };
  }, [updateState, schedulingRequestId]);

  if (state.schedulingRequest.isLoading) return <LoadingDashboard />;

  return <SchedulingRequestContext.Provider value={state}>{children}</SchedulingRequestContext.Provider>;
};
