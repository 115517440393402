import { useEffect } from "react";

import { Container } from "@/components/layout/Container";
import { EventPage, useAnalytics } from "@/hooks/useAnalytics";
import { useHashParams } from "@/hooks/useHashParams";

import { TabsUtil } from "../../../../components/controls/TabsUtil";
import { OnboardingCard } from "./OnboardingCard";
import { BillingTab } from "./tabs/billing/BillingTab";
import { ConfigurationTab } from "./tabs/configuration/ConfigurationTab";
import { DetailsTab } from "./tabs/details/DetailsTab";
import { IntegrationsTab } from "./tabs/integrations/IntegrationsTab";
import { TeamTab } from "./tabs/team/TeamTab";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { organisationConfigDefaults } from "@fyxer-ai/shared";

export enum SettingsTabName {
  CONFIGURATION = "configuration",
  INTEGRATIONS = "integrations",
  BILLING = "billing",
  TEAM = "team",
  DETAILS = "details",
}

export const SettingsPage = () => {
  const { logPageView } = useAnalytics();
  const { tab } = useHashParams();
  const { isAdmin, organisation } = useOrganisation();
  const shouldHideBillingTab = organisation.shouldHideBillingTab ?? organisationConfigDefaults.shouldHideBillingTab;
  const canOnlyAdminsSeeUsers = organisation.canOnlyAdminsSeeUsers ?? organisationConfigDefaults.canOnlyAdminsSeeUsers;
  const shouldHideTeamTab = canOnlyAdminsSeeUsers && !isAdmin;

  useEffect(() => {
    logPageView(EventPage.SETTINGS, { tab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <Container className="flex h-full flex-col space-y-8">
      <OnboardingCard tab={tab} />

      <TabsUtil
        className="flex-shrink flex-grow sm:overflow-scroll"
        tab={tab}
        tabs={[
          {
            value: SettingsTabName.CONFIGURATION,
            label: "Preferences",
            content: <ConfigurationTab />,
          },
          {
            value: SettingsTabName.INTEGRATIONS,
            content: <IntegrationsTab />,
          },
          ...(shouldHideBillingTab ? [] : [{ value: SettingsTabName.BILLING, content: <BillingTab /> }]),
          ...(shouldHideTeamTab ? [] : [{ value: SettingsTabName.TEAM, content: <TeamTab /> }]),
          {
            value: SettingsTabName.DETAILS,
            label: "Organisation",
            content: <DetailsTab />,
          },
        ]}
      />
    </Container>
  );
};
