import { CallRecordingAccessSetting } from "@fyxer-ai/shared";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { z } from "zod";

import { ArrayField } from "@/components/controls/ArrayField";
import { CopyTextButton } from "@/components/controls/CopyUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useQueryParams } from "@/hooks/useQueryParams";
import { Collection } from "@/lib/firebase/Collection";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import { getCallRecordingUrl } from "./getCallRecordingUrl";
import { useBase } from "@/context/BaseContext/state/useBase";

const getTitleFromAccessSetting = (accessSetting: CallRecordingAccessSetting) => {
  switch (accessSetting) {
    case CallRecordingAccessSetting.ONLY_ME:
      return "Restricted";
    case CallRecordingAccessSetting.ORGANISATION:
      return "My Organisation";
    case CallRecordingAccessSetting.ANYONE_WITH_LINK:
      return "Anyone with the link";
  }
};

const getExplanationFromAccessSetting = (accessSetting: CallRecordingAccessSetting) => {
  switch (accessSetting) {
    case CallRecordingAccessSetting.ONLY_ME:
      return "Only myself and the people listed above can view the recording";
    case CallRecordingAccessSetting.ORGANISATION:
      return "Anyone who's a member of your organisation can view the recoding";
    case CallRecordingAccessSetting.ANYONE_WITH_LINK:
      return "Anyone on the internet with the link can view the recording";
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schema = z.object({
  accessSetting: z.nativeEnum(CallRecordingAccessSetting),
  emailsWithAccess: z.array(makeSchemaSafe(z.string().email().max(320))),
});

type EditAccessSettingDialogData = z.infer<typeof schema>;

export const EditAccessSettingDialogContent = ({
  shareLink,
  defaultValues,
  onSubmit,
  title,
}: {
  shareLink: string;
  defaultValues: EditAccessSettingDialogData;
  onSubmit: (data: EditAccessSettingDialogData) => Promise<void>;
  title: string;
}) => {
  const { toast } = useToast();
  const { user } = useBase();
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <FormUtil
        schema={z.object({
          accessSetting: z.nativeEnum(CallRecordingAccessSetting),
          emailsWithAccess: z.array(makeSchemaSafe(z.string().email().max(320))),
        })}
        defaultValues={defaultValues}
        onSubmit={async (data) => {
          await onSubmit(data);
          toast({ title: "Access updated" });
        }}
        submitTitle="Save"
        render={(form) => (
          <>
            <div className="space-y-2">
              <Label>Emails with access</Label>
              <ArrayField
                form={form}
                name={"emailsWithAccess" as never}
                maxLength={50}
                generateEmptyDatum={() => ""}
                shouldHideRemoveButton={(index) => {
                  const emailsWithAccess = form.watch("emailsWithAccess");
                  const email = emailsWithAccess.length > index ? emailsWithAccess[index] : undefined;
                  if (!email) return false;
                  return email === user.value?.email;
                }}
                render={(index) => (
                  <FormFieldUtil
                    className="flex-grow"
                    control={form.control}
                    name={`emailsWithAccess.${index}`}
                    render={({ field }) => <Input {...field} />}
                    isLabelHidden
                  />
                )}
              />
            </div>
            <FormFieldUtil
              control={form.control}
              name="accessSetting"
              title="General access"
              description={getExplanationFromAccessSetting(form.watch("accessSetting"))}
              render={({ field }) => (
                <SelectUtil
                  {...field}
                  items={Object.values(CallRecordingAccessSetting).map((accessSetting) => ({
                    value: accessSetting,
                    label: getTitleFromAccessSetting(accessSetting),
                  }))}
                />
              )}
            />
          </>
        )}
      />
      <CopyTextButton value={shareLink} />
    </DialogContent>
  );
};

export const EditAccessSettingDialog = () => {
  const shouldEditAccess = !!useQueryParams().shouldEditAccess;
  const { callRecording, callRecordingId } = useCallRecording();
  const { accessSetting } = callRecording;
  const emailsWithAccess = callRecording.emailsWithAccess ?? [];

  const callRecordingShareLink = getCallRecordingUrl({ callRecordingId });
  const [open, setOpen] = useState(shouldEditAccess);

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <DialogTrigger asChild>
        <Button>Share</Button>
      </DialogTrigger>
      <EditAccessSettingDialogContent
        title="Share call recording"
        onSubmit={async (data) => {
          await updateDoc(doc(Collection.CallRecording, callRecordingId), data);
          setOpen(false);
        }}
        defaultValues={{ accessSetting, emailsWithAccess }}
        shareLink={callRecordingShareLink}
      />
    </Dialog>
  );
};
