import { ReactNode } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useSchedulingRequestNullable } from "../state/useSchedulingRequestNullable";

export const SchedulingRequestGuard = ({ children }: { children: ReactNode }) => {
  const { schedulingRequest } = useSchedulingRequestNullable();

  if (schedulingRequest.isLoading) return <LoadingDashboard />;

  if (schedulingRequest.value === undefined) throw new Error("scheduling request is missing");

  return children;
};
