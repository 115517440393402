export const ZoomDocumentation = () => {
  return (
    <div className="w-full">
      <div className="m-auto max-w-[800px] space-y-8 px-4 py-16">
        <h1>Zoom Integration</h1>

        <div className="space-y-4">
          <h2>Usage</h2>
          <div className="space-y-2">
            <h3>Overview</h3>
            <p>
              Fyxer allows you to send a bot to your Zoom meetings to automatically generate a meeting summary and draft
              follow-up emails. This ensures you never miss out on key points discussed during your meetings.
            </p>
          </div>

          <div className="space-y-2">
            <h3>Sending the Bot to a Zoom Meeting</h3>
            <ul>
              <li>
                <strong>Schedule a Meeting</strong>: Schedule your Zoom meeting as usual. Make sure it has at least two
                attendees, has a valid meeting link, and you're either the host of the meeting or you've accepted the
                invite.
              </li>
              <li>
                <strong>Add the Bot</strong>: Invite the bot to your meeting by going to the 'Meeting Notetaker' section
                in the dashboard and flicking the switch to on.
              </li>
              <li>
                <strong>Receive Summaries and Follow-Ups</strong>: After the meeting, you will receive an email with the
                meeting summary. A draft follow-up email will be prepared and left in your inbox for review.
              </li>
            </ul>
          </div>

          <div className="space-y-2">
            <h3>Features</h3>
            <ul>
              <li>
                <strong>Meeting Summaries</strong>: Detailed summaries of your meetings.
              </li>
              <li>
                <strong>Follow-Up Email Drafts</strong>: Automated draft emails prepared for you to send to the meeting
                invitees.
              </li>
              <li>
                <strong>Easy Integration</strong>: Simply add the bot to your meeting invites.
              </li>
            </ul>
          </div>
        </div>

        <div className="space-y-4">
          <h2>Installation</h2>
          <ul>
            <li>
              <strong>Login to Your Fyxer Account</strong>: Go to the Fyxer dashboard and log in.
            </li>
            <li>
              <strong>Navigate to Integrations</strong>: Click on the 'Integrations' tab in the dashboard.
            </li>
            <li>
              <strong>Select Zoom</strong>: Find Zoom in the list of available integrations and click 'Connect'.
            </li>
            <li>
              <strong>Grant Permissions</strong>: You will be redirected to the Zoom OAuth permission page. Click
              'Authorize' to grant permissions to Fyxer.
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h2>Uninstallation</h2>
          <ul>
            <li>
              <strong>Login to Your Zoom Account</strong>: Go to{" "}
              <a href="https://zoom.us/" target="_blank">
                Zoom
              </a>{" "}
              and log in.
            </li>
            <li>
              <strong>Navigate to App Marketplace</strong>: Click on your profile icon and select 'App Marketplace'.
            </li>
            <li>
              <strong>Manage Installed Apps</strong>: Click on 'Manage' and then 'Installed Apps'.
            </li>
            <li>
              <strong>Find Fyxer</strong>: Search for the Fyxer app in the list.
            </li>
            <li>
              <strong>Uninstall</strong>: Click on the Fyxer app and then click 'Uninstall'.
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h2>Troubleshooting</h2>
          <ul>
            <li>
              <strong>Bot Not Joining Meetings</strong>: Ensure the switch for the meeting was set to on, there are two
              or more meeting attendees, and either you're the meeting host or you've accepted the meeting invite.
            </li>
            <li>
              <strong>No Meeting Summary Received</strong>: Confirm that the meeting took place as scheduled and that
              the bot was successfully added. Check your spam folder for the summary email.
            </li>
            <li>
              <strong>Authorization Issues</strong>: If you encounter issues during installation, ensure that you are
              logged into the correct Zoom account and that you have granted all required permissions.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
