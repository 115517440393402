import { useSchedulingRequestNullable } from "./useSchedulingRequestNullable";

export const useSchedulingRequest = () => {
  const {
    schedulingRequestId: { value: schedulingRequestId, isLoading: isSchedulingRequestIdLoading },
    schedulingRequest: { value: schedulingRequest, isLoading: isSchedulingRequestLoading },
  } = useSchedulingRequestNullable();

  if (isSchedulingRequestIdLoading) throw new Error("scheduling request id is still loading");
  if (isSchedulingRequestLoading) throw new Error("scheduling request is still loading");

  if (schedulingRequestId === undefined) throw new Error("scheduling request id is missing");
  if (schedulingRequest === undefined) throw new Error("scheduling request is missing");

  return { schedulingRequestId, schedulingRequest };
};
