import { AutoJoinMeetingConfiguration, Membership, OauthIntegration } from "@fyxer-ai/shared";
import { QueryDocumentSnapshot, updateDoc } from "firebase/firestore";
import { Info } from "lucide-react";
import { forwardRef, useEffect } from "react";
import { Button, ButtonProps } from "@/components/ui/button";

import gmailUrl from "@/assets/company-logos/gmail.png";
import outlookUrl from "@/assets/company-logos/outlook.png";
import microsoftTeamsUrl from "@/assets/company-logos/microsoft-teams.png";
import zoomUrl from "@/assets/company-logos/zoom.png";
import googleMeetUrl from "@/assets/company-logos/google-meet.png";

import sortingGif from "@/assets/mockups/sorting.gif";
import beforeAfterPng from "@/assets/mockups/before-after.png";
import inboxPng from "@/assets/mockups/inbox.png";
import { Progress } from "@/components/ui/progress";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { EventName, EventPage, useAnalytics } from "@/hooks/useAnalytics";
import { useUpdateState } from "@/hooks/useUpdateState";
import { getMeetingConfigName } from "@/routes/org/[organisationId]/settings/tabs/configuration/ConfigurationTab";
import {
  GoogleDisclosure,
  integrationData,
  IntegrationDatum,
} from "@/routes/org/[organisationId]/settings/tabs/integrations/integrationData";
import { useOauthRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/useOauthRedirect";
import { Spinner } from "@/components/layout/SpinnerPage";
import { OnboardingIntegrationsRack } from "../ConnectionsGuard";

type NoConnectionsPageState = {
  autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration;
  areLabelsEnabled: boolean;
  redirectingIntegration: OauthIntegration | undefined;
};

const ConnectionButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    integration: OauthIntegration;
    redirectingIntegration: OauthIntegration | undefined;
    connectIntegration: (integration: OauthIntegration) => Promise<void>;
  }
>(({ integration, redirectingIntegration, connectIntegration, ...props }, ref) => {
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;

  return (
    <Button
      variant="outline"
      size="lg"
      className="w-full flex-grow drop-shadow-xl"
      {...props}
      ref={ref}
      onClick={() => connectIntegration(integration)}
      disabled={!!redirectingIntegration}
    >
      {redirectingIntegration === integration ? (
        <Spinner className="m-0 flex-grow-0" />
      ) : (
        <img src={logoUrl} className="h-4 w-4" />
      )}
      Connect{redirectingIntegration === integration ? "ing" : ""} {title}
    </Button>
  );
});

const OnboardingCalendarPage = ({
  connectIntegration,
  autoJoinMeetingConfiguration,
  redirectingIntegration,
  onSelectMeetingConfig,
}: {
  connectIntegration: (integration: OauthIntegration) => Promise<void>;
  autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration;
  redirectingIntegration: OauthIntegration | undefined;
  onSelectMeetingConfig: (value: AutoJoinMeetingConfiguration) => void;
}) => {
  return (
    <div className="mx-auto mb-6 max-w-lg px-6 py-6">
      <OnboardingPageWizardHeader title="Let's get your assistant setup" value={100} stepTitle="Meeting notes" />
      <h3 className="text-semibold text-lg">Write meeting notes and draft follow-up emails</h3>
      <p className="mb-6 mt-3 text-base">
        Fyxer AI will ask to join your meetings to take notes. As soon as the meeting ends, you'll find
        better-than-human meeting notes in your inbox, plus a follow-up email ready to send.
      </p>
      <OnboardingIntegrationsRack
        integrations={[
          { src: zoomUrl, alt: "Zoom" },
          { src: googleMeetUrl, alt: "Google Meet" },
          { src: microsoftTeamsUrl, alt: "Microsoft Teams" },
        ]}
      />
      <img
        src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/6746182c39e686349203a6bd_video-call_fyxer-min.jpg"
        className="mt-3 w-full"
        alt="Fyxer recording a meeting"
      />

      <div className="mt-6 space-y-2">
        <p className="text-sm font-medium">Meetings the notetaker should join</p>
        <SelectUtil
          value={autoJoinMeetingConfiguration}
          onChange={(value) => onSelectMeetingConfig(value)}
          items={Object.values(AutoJoinMeetingConfiguration).map((value) => ({
            value,
            label: getMeetingConfigName(value),
          }))}
        />
      </div>

      <div className="mt-4">
        <div className="mb-3 flex flex-col gap-3">
          {[OauthIntegration.GOOGLE_CALENDAR, OauthIntegration.MICROSOFT_OUTLOOK_CALENDAR].map((k) => (
            <ConnectionButton
              key={k}
              {...{
                integration: k,
                connectIntegration,
                redirectingIntegration,
              }}
            />
          ))}
        </div>

        <GoogleDisclosure />
      </div>
    </div>
  );
};

const OnboardingEmailPage = ({
  areLabelsEnabled,
  onUpdateLabels,
  connectIntegration,
  redirectingIntegration,
}: {
  connectIntegration: (integration: OauthIntegration) => Promise<void>;
  areLabelsEnabled: boolean;
  redirectingIntegration: OauthIntegration | undefined;
  onUpdateLabels: (value: boolean) => void;
}) => (
  <div className="mx-auto mb-6 max-w-lg px-6 py-6">
    <OnboardingPageWizardHeader title="Let's get your assistant setup" value={50} stepTitle="Organise your inbox" />

    <h3 className="text-semibold text-lg">AI-Powered email sorting and replies</h3>
    <p className="mb-3 mt-3 text-base">
      Connect your Gmail/Outlook to turn on automatic email sorting. All emails will be organised into folders, making
      it easy to spot what needs your attention.
    </p>

    <OnboardingIntegrationsRack
      integrations={[
        { src: gmailUrl, alt: "Gmail" },
        { src: outlookUrl, alt: "Outlook" },
      ]}
    />

    <img src={sortingGif} className="mt-3 w-full" alt="Fyxer sorting your inbox" />
    <p className="mb-3 mt-3 text-base">
      Spam, marketing emails, and AI-generated junk will be filtered out to keep your inbox clean and manageable.
    </p>
    <img src={beforeAfterPng} className="" alt="Before and after Fyxer organising your inbox" />

    <p className="mb-3 mt-6 text-base">
      When you check your inbox, every email requiring a response will have a reply pre-drafted in your tone, ready for
      you to send.
    </p>
    <img src={inboxPng} className="mt-3 w-full" alt="Fyxer drafting a reply" />

    <div className="mb-6 mt-8 flex items-center justify-center gap-3 rounded bg-gray-200 p-3">
      <Info size={18} className="flex-shrink-0" />{" "}
      <p className="text-xs">
        Fyxer will never send an email on your behalf. We leave drafts for you to edit and send.
      </p>
    </div>

    <div className="mb-6">
      <div className="mb-3 space-y-2">
        <p className="mb-3 text-sm font-medium">Categorise my inbox</p>
        <SelectUtil
          value={areLabelsEnabled ? "yes" : "no"}
          onChange={(value) => onUpdateLabels(value === "yes")}
          items={["yes", "no"]}
        />
      </div>
    </div>

    <div>
      <div className="mb-3 flex flex-col gap-3">
        {[OauthIntegration.GMAIL, OauthIntegration.MICROSOFT_OUTLOOK_EMAIL].map((k) => (
          <ConnectionButton
            {...{
              integration: k,
              connectIntegration,
              redirectingIntegration,
            }}
          />
        ))}
      </div>

      <GoogleDisclosure />
    </div>
  </div>
);

const OnboardingPageWizardHeader = ({
  title,
  value,
  stepTitle,
}: {
  title: string;
  value: number;
  stepTitle: string;
}) => (
  <>
    <h2 className="mb-6 mt-0 text-center text-2xl md:text-3xl">{title}</h2>
    <Progress value={value} className="mb-3 w-full" />
    <p className="mb-6 text-left text-sm font-semibold">{stepTitle}</p>
  </>
);

const OnboardingPageWizard = ({
  userMembership,
  tab,
}: {
  userMembership: QueryDocumentSnapshot<Membership>;
  tab: "email" | "calendar";
}) => {
  const { logEvent, logPageView } = useAnalytics();
  const [state, updateState] = useUpdateState<NoConnectionsPageState>({
    autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration.ALWAYS,
    areLabelsEnabled: true,
    redirectingIntegration: undefined,
  });
  const { handleOauthRedirect } = useOauthRedirect();

  const connectIntegration = async (integration: OauthIntegration) => {
    const { organisationId } = userMembership.data();
    updateState({ redirectingIntegration: integration });
    await updateDoc(userMembership.ref, {
      autoJoinMeetingConfiguration: state.autoJoinMeetingConfiguration,
      areLabelsEnabled: state.areLabelsEnabled,
    });
    const rest = { type: "integration" as const, integration };
    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
    logEvent(EventName.COMPLETE_OAUTH_CONNECTION, { integration, organisationId });
  };

  useEffect(() => {
    logPageView(EventPage.ONBOARDING);
  }, [logPageView]);

  return {
    email: (
      <OnboardingEmailPage
        {...{
          connectIntegration,
          areLabelsEnabled: state.areLabelsEnabled,
          redirectingIntegration: state.redirectingIntegration,
          onUpdateLabels: (value) => updateState({ areLabelsEnabled: value }),
        }}
      />
    ),
    calendar: (
      <OnboardingCalendarPage
        {...{
          connectIntegration,
          autoJoinMeetingConfiguration: state.autoJoinMeetingConfiguration,
          redirectingIntegration: state.redirectingIntegration,
          onSelectMeetingConfig: (value) => updateState({ autoJoinMeetingConfiguration: value }),
        }}
      />
    ),
  }[tab];
};

export default OnboardingPageWizard;
