import { parseCustomEmailDomain } from "@fyxer-ai/shared";
import { OAuthProvider, SAMLAuthProvider } from "firebase/auth";
import { Link } from "react-router-dom";
import { z } from "zod";

import googleUrl from "@/assets/company-logos/google.png";
import microsoftUrl from "@/assets/company-logos/microsoft.png";
import { CopyTextButton } from "@/components/controls/CopyUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { useUpdateState } from "@/hooks/useUpdateState";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";
import { LocalStorageBooleanKey, storage } from "@/lib/storage";
import { ua } from "@/lib/userAgent";
import { useSignInWithPopup } from "@/routes/auth/actions/useSignInWithPopup";

import { fetchSingleSignOnDetails } from "./fetchSingleSignOnDetails";
import { getAuthProvider } from "./getAuthProvider";
import { AuthProviderType } from "./types/AuthProviderType";
import { AuthType } from "./types/AuthType";
import { useEffect } from "react";
import { getCompanyName, getSsoDetails } from "@/lib/getWhitelabelProvider";

const UnsupportedBrowserPage = () => {
  const browserName = ua.browser.name;
  const recommendedBrowser = ua.os.name === "iOS" ? "Safari" : "Chrome";
  return (
    <div className="flex h-screen items-center justify-center p-4">
      <div className="space-y-4">
        <h2>Unsupported browser</h2>
        <p>
          It looks like you're using {browserName}'s browser. Google doesn't allow you to sign up using the{" "}
          {browserName} browser.
        </p>
        <p>Please open this link in another browser, such as {recommendedBrowser}.</p>
        <CopyTextButton variant="default" value={window.location.href} />
      </div>
    </div>
  );
};

const getAuthProviderFromSsoDetails = ({ providerId, ssoType }: { providerId: string; ssoType: string }) => {
  return ssoType === "SAML" ? new SAMLAuthProvider(`saml.${providerId}`) : new OAuthProvider(`oidc.${providerId}`);
};

const SsoForm = () => {
  const { toast } = useToast();
  const signInWithPopup = useSignInWithPopup();

  return (
    <FormUtil
      schema={z.object({ email: makeSchemaSafe(z.string().email().min(1).max(320)) })}
      defaultValues={{ email: "" }}
      render={(form) => (
        <>
          <p>
            To sign up with SSO, your IT team will need to help us get you set up. To get in touch, email
            archie@fyxer.com or speak to us by using the support function in the bottom right.
          </p>
          <FormFieldUtil control={form.control} name="email" render={({ field }) => <Input {...field} />} />
        </>
      )}
      onSubmit={async (data) => {
        const domain = parseCustomEmailDomain(data.email);

        if (!domain) {
          toast({
            title: "Invalid email",
            description: "Your email address doesn't contain a company domain. This is required to sign in with SSO.",
          });
          return;
        }

        const singleSignOnDetails = await fetchSingleSignOnDetails(domain);

        if (!singleSignOnDetails) {
          toast({
            title: "SSO not up set up",
            description: `SSO hasn't been set up for your email domain (${domain}). Email archie@fyxer.com to enable SSO.`,
            variant: "destructive",
          });
          return;
        }

        signInWithPopup.mutate(getAuthProviderFromSsoDetails(singleSignOnDetails));
      }}
    />
  );
};

const LegalOptins = ({
  allowsMarketing,
  setAllowsMarketing,
}: {
  allowsMarketing: boolean;
  setAllowsMarketing: (allowed: boolean) => void;
}) => {
  return (
    <div className="space-y-2">
      <p className="text-center text-xs text-slate-400">
        {`By signing up, you agree to our `}
        <Link to="/terms-of-use" target="_blank" className="font-semibold hover:opacity-80">
          Terms of Use
        </Link>
        {` and `}
        <Link to="/privacy-policy" target="_blank" className="font-semibold hover:opacity-80">
          Privacy Policy
        </Link>
        .
      </p>

      {!storage.local.boolean(LocalStorageBooleanKey.ALLOWED_MARKETING_ON_SIGN_UP).get() && (
        <div className="flex items-center justify-center gap-x-2">
          <Checkbox
            checked={allowsMarketing}
            className="h-3 w-3 border-slate-400 data-[state=checked]:bg-slate-400"
            onCheckedChange={(c) => setAllowsMarketing(c.valueOf() as boolean)}
            id="marketing"
          />
          <label htmlFor="marketing" className="text-xs text-slate-400">
            Receive news of product updates and promotions
          </label>
        </div>
      )}
    </div>
  );
};

const SignupLoginSwitch = ({ authType }: { authType: AuthType }) => (
  <p className="text-center">
    {`${authType === AuthType.SIGN_UP ? "Already" : "Don't"} have an account? `}
    <Link
      to={`/auth/${authType === AuthType.SIGN_UP ? "log-in" : "sign-up"}`}
      className="font-semibold text-indigo-500 hover:opacity-80"
    >
      {authType === AuthType.SIGN_UP ? "Log in" : "Sign up"}
    </Link>
  </p>
);

export const AuthForm = ({ authType }: { authType: AuthType }) => {
  const signInWithPopup = useSignInWithPopup();
  const { logButtonPress } = useAnalytics();
  const ssoDetails = getSsoDetails();
  const [state, updateState] = useUpdateState({
    allowsMarketing: true,
    useSso: !!ssoDetails,
    isShowingUnsupportedBrowserPage: false,
  });

  useEffect(() => {
    storage.local.boolean(LocalStorageBooleanKey.ALLOWED_MARKETING_ON_SIGN_UP).set(state.allowsMarketing);
  }, [state.allowsMarketing]);

  useEffect(() => {
    const ssoDetails = getSsoDetails();
    if (!ssoDetails) return;
    signInWithPopup.mutate(getAuthProviderFromSsoDetails(ssoDetails));
  }, []);

  const buttonAction = authType === AuthType.SIGN_UP ? "Sign up" : "Log in";

  if (state.isShowingUnsupportedBrowserPage) return <UnsupportedBrowserPage />;

  if (ssoDetails)
    return (
      <div className="mx-auto w-full max-w-md space-y-6">
        <h1 className="text-center">Log in</h1>
        <p>
          {getCompanyName()} requires you to log in via SSO. Please click the button below if not redirected
          automatically.
        </p>
        <Button className="w-full" onClick={() => signInWithPopup.mutate(getAuthProviderFromSsoDetails(ssoDetails))}>
          Log in
        </Button>
        <LegalOptins
          allowsMarketing={state.allowsMarketing}
          setAllowsMarketing={(a) => updateState({ allowsMarketing: a })}
        />
      </div>
    );

  return (
    <div className="mx-auto w-full max-w-md space-y-6">
      <h1 className="text-center">{authType === AuthType.SIGN_UP ? "Start a free trial" : "Log in"}</h1>

      <div className="space-y-4">
        {state.useSso ? (
          <SsoForm />
        ) : (
          <div className="space-y-2">
            <Button
              className="w-full gap-x-2"
              variant="outline"
              onClick={async () => {
                const browserName = ua.browser.name ?? "NONE";
                if (["Facebook", "LinkedIn", "Instagram"].includes(browserName)) {
                  updateState({ isShowingUnsupportedBrowserPage: true });
                  return;
                }
                logButtonPress(EventButton.START_AUTH_PROCESS, { provider: AuthProviderType.GOOGLE });
                signInWithPopup.mutate(getAuthProvider(AuthProviderType.GOOGLE));
              }}
            >
              <img className="h-6 w-6" src={googleUrl} />
              {buttonAction} with Google
            </Button>
            <Button
              className="w-full gap-x-2"
              variant="outline"
              onClick={() => {
                logButtonPress(EventButton.START_AUTH_PROCESS, { provider: AuthProviderType.MICROSOFT });
                signInWithPopup.mutate(getAuthProvider(AuthProviderType.MICROSOFT));
              }}
            >
              <img className="h-6 w-6" src={microsoftUrl} />
              {buttonAction} with Microsoft
            </Button>
            {authType === AuthType.LOG_IN && (
              <Button
                className={`w-full text-sm text-gray-500 underline`}
                variant="link"
                onClick={() => {
                  logButtonPress(EventButton.REQUEST_SAML_SSO);
                  updateState({ useSso: !state.useSso });
                }}
              >
                Log in with{state.useSso ? "out" : ""} SSO
              </Button>
            )}
          </div>
        )}

        {authType === AuthType.SIGN_UP && (
          <LegalOptins
            allowsMarketing={state.allowsMarketing}
            setAllowsMarketing={(allows) => updateState({ allowsMarketing: allows })}
          />
        )}
      </div>
      <SignupLoginSwitch authType={authType} />
    </div>
  );
};
