import { OnboardingIntegrationsRack } from "@/context/OrganisationContext/guards/ConnectionsGuard";
import gmailUrl from "@/assets/company-logos/gmail.png";
import outlookUrl from "@/assets/company-logos/outlook.png";
import { Link } from "react-router-dom";
import { SchedulingStates } from "@/routes/scheduling/[schedulingRequestId]/SchedulingRequestView";

export const ConfirmationComponent = ({ userName, state }: { userName: string; state: SchedulingStates }) => {
  return (
    <div className="flex h-full flex-col items-start justify-between">
      <div>
        <h1 className="mb-4 text-5xl font-bold text-gray-900">
          {state === "pending-event" ? `Creating Event` : `Sorted.`}
        </h1>
        <p>We've notified {userName} about your meeting. Your invite will be with you shortly.</p>
      </div>
      <div className="mt-5">
        <p className="pb-5">
          <span>Join the thousands of professionals saving at least an hour a day by connecting your email </span>
          <Link to="/org" target="_blank" rel="noreferrer" className="flex-shrink-0">
            <span className="text-purple-500 hover:underline">here</span>
          </Link>
          .
        </p>
        {/* TO-DO: Make these images lead to connections page */}
        <OnboardingIntegrationsRack
          integrations={[
            { src: gmailUrl, alt: "Gmail" },
            { src: outlookUrl, alt: "Outlook" },
          ]}
          text="Compatible with: "
        />
      </div>
    </div>
  );
};
