import { useEffect, useState } from "react";
import { EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";
import { Link } from "react-router-dom";
import { Logo } from "@/components/layout/TopBar";
import { getMarketingSiteUrl } from "@/lib/getWhitelabelProvider";
import mockupImage from "@/assets/mockups/inbox.png";

import { AuthPageShell } from "./AuthPageShell";
import { AuthType } from "./types/AuthType";

export const SignUpPage = () => {
  const { return_path, from_marketing } = useQueryParams();
  const { getFeatureFlag, overrideFlag } = useAnalytics();
  const [outcomeExp, setOutcomeExp] = useState(false);
  const isFromMarketing = from_marketing === "yes";
  const returnPath = return_path ?? "/org";
  useLogPageView(EventPage.SIGN_UP, { returnPath, isFromMarketing });

  useEffect(() => {
    setOutcomeExp(getFeatureFlag("sign-up-outcome") === "test");
  }, [getFeatureFlag, overrideFlag]);

  if (outcomeExp) {
    return (
      <>
        <div className="grid grid-cols-1 items-center lg:grid-cols-2">
          <div className="flex flex-grow flex-col pb-6 sm:pb-8 lg:h-screen lg:pb-0">
            <div className="mb-3 border border-slate-100 bg-white px-4 py-2 lg:mb-0">
              <Link to={getMarketingSiteUrl()} target="_blank" rel="noreferrer">
                <Logo />
              </Link>
            </div>
            <AuthPageShell outcomeExp={outcomeExp} authType={AuthType.SIGN_UP} />
          </div>
          <div className="flex items-center justify-center bg-[#1f13f9] px-6 py-6 lg:h-screen lg:px-0 lg:py-0">
            <div className="w-full max-w-md">
              <h2 className="mb-3 text-3xl text-white">Get back one hour, every day</h2>
              <p className="text-lg text-white">
                Sign up, connect your inbox, and reclaim your time with AI-powered{" "}
                <span className="font-bold text-white">email sorting</span>,{" "}
                <span className="font-bold text-white">drafted responses</span>, and{" "}
                <span className="font-bold text-white">meeting notes</span>.
              </p>
              <img className="mt-6 w-full" src={mockupImage} />
              <p className="mb-3 mt-12 text-center text-sm font-semibold text-white">Trusted by</p>
              <div className="grid grid-cols-4 items-center gap-x-6 lg:gap-x-12">
                <img
                  src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/671a58ca37949cb2533c8dc2_pavillion.png"
                  alt="Pavillion"
                  className="w-full grayscale invert"
                />
                <img
                  src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/675840146c69f201e125c3cc_soundcloud-vector-fyxer.svg"
                  alt="Soundcloud"
                  className="w-full grayscale invert"
                />
                <img
                  src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/671a58ca2846a43c87e0e010_la-fosse.png"
                  alt="La Fosse"
                  className="w-full grayscale invert"
                />
                <img
                  src="https://cdn.prod.website-files.com/670df377193f5bd7cbb4bcb6/671a58ca5bf02c8ff090d080_celonis.png"
                  alt="Celonis"
                  className="w-full grayscale invert"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <AuthPageShell outcomeExp={false} authType={AuthType.SIGN_UP} />;
};
